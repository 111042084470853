import React, { FunctionComponent, useEffect } from 'react';
import { useRouter } from 'next/router';
import SelectInput from '@havenengineering/module-shared-library/dist/form/components/SelectInput';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';

import { useAccountBalance } from '../../hooks/useAccountBalance';
import { useScreenWidth } from '../../hooks/useScreenWidth';
import styles from './AccountSelector.module.scss';

export const AccountSelector: FunctionComponent = () => {
  const { loggedInUser, activeAccountId } = useAuthContext();
  const { updateAccountBalance, isUpdating } = useAccountBalance();
  const mobileView = useScreenWidth(960);
  const router = useRouter();

  useEffect(() => {
    if (activeAccountId) {
      updateAccountBalance(activeAccountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAccountBalance]);

  if (!loggedInUser || (loggedInUser && loggedInUser.accounts.length < 2)) {
    return null;
  }

  const options = loggedInUser.accounts.map((account) => ({
    label: `${account.areaName} - ${account.pitchNumber}`,
    value: account.accountID.toString(),
  }));

  return (
    <div
      className={
        router.pathname === '/bookings' && !mobileView
          ? styles.bookingsPositioning
          : ''
      }
    >
      <SelectInput
        className={styles.accountSelector}
        id="activeAccount"
        label="Selected holiday home"
        options={options}
        value={activeAccountId?.toString() || ''}
        disabled={isUpdating}
        onChange={(selected) => {
          if (selected) updateAccountBalance(+selected);
        }}
      />
    </div>
  );
};
