import { useCallback, useEffect, useState } from 'react';
import getConfig from 'next/config';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';
import { setActiveAccountCookie } from '@havenengineering/module-shared-owners-ui/dist/utils/activeAccountUtils';

const {
  publicRuntimeConfig: { PUBLIC_OWNERS_ACTIVE_ACCOUNT_COOKIE_DOMAIN },
} = getConfig();

export const useAccountBalance = () => {
  const { loggedInUser, activeAccountId, setActiveAccountId } =
    useAuthContext();
  const [accountId, setAccountId] = useState(activeAccountId);

  useEffect(() => {
    if (loggedInUser && accountId) {
      setActiveAccountId(accountId);
      setActiveAccountCookie(
        accountId,
        PUBLIC_OWNERS_ACTIVE_ACCOUNT_COOKIE_DOMAIN || ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const updateAccountBalance = useCallback((accountId: number) => {
    setAccountId(accountId);
  }, []);

  return { updateAccountBalance, isUpdating: false };
};
