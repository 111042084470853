import React, { FunctionComponent, useEffect, useState } from 'react';
import InlineMessaging, {
  InlineMessagingTheme,
} from '@havenengineering/module-shared-library/dist/components/InlineMessaging/InlineMessaging';
import { TextInputField } from '@havenengineering/module-shared-library/dist/form/components/TextInput';
import useForm, {
  emailValidator,
} from '@havenengineering/module-shared-library/dist/form/hooks/useForm';
import { Button } from '@havenengineering/module-shared-owners-ui/dist/components/Button';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';
import { DateTime } from 'luxon';

import { now } from '../../helpers/dateTime';
import { useIsMounted } from '../../hooks/useIsMounted';
import {
  getPrivateGuestEmailConfirmationInfo,
  resendBookingEmail,
} from '../helpers/bookings';
import { useBooking } from './bookingContext';

const LettingEmailEditor: FunctionComponent = () => {
  const { loggedInUser } = useAuthContext();
  const isMounted = useIsMounted();

  const { booking } = useBooking();

  const [infoLoading, setInfoLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailConfirmationInfo, setEmailConfirmationInfo] =
    useState<PrivateGuestEmailConfirmationInfo | null>(null);

  const [error, setError] = useState<string>('');

  const { fields, submit } = useForm<any>({
    initialState: {
      email: '',
    },
    schema: {
      email: {
        required: true,
        validator: emailValidator,
      },
    },
    submitCallback: async (state) => {
      handleSubmit(state);
    },
  });

  useEffect(() => {
    const getUser = async () => {
      setInfoLoading(true);
      try {
        const info = await getPrivateGuestEmailConfirmationInfo(
          booking.shortCode
        );
        if (isMounted.current) {
          setEmailConfirmationInfo(info);
          fields.email.setValue(info.email);
          setLoading(false);
        }
      } catch (error) {
        setError('Something went wrong, please try again');
      } finally {
        setInfoLoading(false);
      }
    };
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.shortCode, isMounted]);

  const handleSubmit = async (state: any) => {
    setError('');
    setSuccess(false);
    setLoading(true);
    try {
      const updatedInfo = await resendBookingEmail(
        booking.shortCode,
        state.email,
        (loggedInUser as User).email
      );
      setEmailConfirmationInfo(updatedInfo);
      setSuccess(true);
    } catch (error) {
      setError('Something went wrong, please try again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={(event) => event.preventDefault()}
      data-testid="letter-email-editor"
    >
      <p className="owners-body">
        Edit the Email Address and/or Resend the email confirmation to your
        Guest. Please note, we can only send one email per day.
        <br />
        {emailConfirmationInfo?.confirmationEmailResentAt && (
          <>
            Last sent:{' '}
            <span>
              {DateTime.fromJSDate(
                emailConfirmationInfo.confirmationEmailResentAt
              ).toISODate()}
            </span>
          </>
        )}
      </p>
      <TextInputField
        className="fs-mask"
        id="email"
        label="Email"
        field={fields.email}
        type="email"
      />
      {error && (
        <InlineMessaging
          type={InlineMessagingTheme.ERROR_THEME}
          title="Error"
          message={error}
          dismissible={false}
        />
      )}
      {!infoLoading && (
        <Button
          onClick={() => submit()}
          isLoading={loading}
          disabled={
            emailConfirmationInfo?.confirmationEmailResentAt &&
            DateTime.fromJSDate(
              emailConfirmationInfo.confirmationEmailResentAt
            ).hasSame(now(), 'day')
          }
        >
          Save and Resend Confirmation Email
        </Button>
      )}
      {success && (
        <InlineMessaging
          type={InlineMessagingTheme.SUCCESS_THEME}
          title="Success"
          message="Update was successful"
          dismissible={false}
        />
      )}
    </form>
  );
};

export default LettingEmailEditor;
