import React, { FunctionComponent } from 'react';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';

import { EarningPotential } from '../Pricing/EarningPotential';
import styles from './SiteFeeHolding.module.scss';

const SiteFeeHolding: FunctionComponent = () => {
  const { activeAccount } = useAuthContext();

  return (
    <div className={styles.siteFeeHolding}>
      <h1>Finance</h1>
      <div className={styles.info}>
        <img src="/assets/icon_info_message.svg" />
        <h2>We&apos;re on it!</h2>
        <p>We are currently preparing your documents for the annual charges.</p>
        <p>
          <b>We&apos;ll email you once these documents are ready.</b>
        </p>
      </div>
      {activeAccount?.accountID && activeAccount?.pitchStatus && (
        <div className={styles.widget}>
          <EarningPotential
            accountId={activeAccount.accountID}
            pitchStatus={activeAccount.pitchStatus}
          />
        </div>
      )}
    </div>
  );
};

export default SiteFeeHolding;
