import { useCallback, useEffect, useState } from 'react';
import getConfig from 'next/config';
import { NextRouter, useRouter } from 'next/router';
import { LoadingIndicator } from '@havenengineering/module-shared-library/dist/components/LoadingIndicator/LoadingIndicator';
import { AuthPageAlert } from '@havenengineering/module-shared-owners-ui/dist/components/AuthPageAlert/AuthPageAlert';
import { MissingAccount } from '@havenengineering/module-shared-owners-ui/dist/components/MissingAccount/MissingAccount';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';
import {
  evalActiveAccountCookie,
  setActiveAccountCookie,
} from '@havenengineering/module-shared-owners-ui/dist/utils/activeAccountUtils';
import { getRedirectUrl } from '@havenengineering/module-shared-owners-ui/dist/utils/authUtils';
import { isGuest as isGuestCookie } from '@havenengineering/module-shared-owners-ui/dist/utils/guest';

import { isEligibleToLetWithHaven } from '../helpers/calendar';
import {
  fetchWrapper,
  withAccountApiBaseUrl,
  withApiBaseUrl,
} from '../helpers/fetch';
import { MainLayout } from '../layouts/MainLayout';
import styles from '../styles/pages/homepage.module.scss';
import { LettingConfigEntry } from '../types/lettingConfig';

const {
  publicRuntimeConfig: {
    PUBLIC_ARRIVAL_APP_URL,
    PUBLIC_LOGIN_URL,
    PUBLIC_OWNERS_ACTIVE_ACCOUNT_COOKIE_DOMAIN,
  },
} = getConfig();

const REDIRECT_TARGETS: { [key: string]: string } = {
  '/bookings': PUBLIC_ARRIVAL_APP_URL || '/',
};

type AuthenticationWrapper = {
  children: JSX.Element;
  availableForGuests?: boolean;
};

const redirect = (router: NextRouter, path?: string) =>
  router.push(
    getRedirectUrl(
      path || PUBLIC_LOGIN_URL || '/',
      window.location.origin,
      router.asPath
    )
  );

const Loading = () => (
  <MainLayout>
    <div className={styles.loadingContainer}>
      <LoadingIndicator loading />
    </div>
  </MainLayout>
);

export const AuthenticationWrapper = ({
  children,
  availableForGuests = false,
}: AuthenticationWrapper) => {
  const router = useRouter();
  const [isAccountMissing, setIsAccountMissing] = useState(false);
  const [isAnonymus, setIsAnonymus] = useState(false);
  const {
    setUser,
    setActiveAccountId,
    userType,
    shouldRefetchUser,
    refetchUser,
    setLettingInformation,
  } = useAuthContext();

  const fetchLettingSummary = async (accountId: number) => {
    try {
      return await fetchWrapper(
        withApiBaseUrl(`/letting/letting-summary/${accountId}`),
        {
          method: 'GET',
          credentials: 'include',
        },
        false
      );
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const fetchAndSetLettingInformation = useCallback(
    async (user, evalActiveAccountId) => {
      const account = user.accounts.find(
        (acc: { accountID: number; accountNo: string; vanYear: number }) =>
          acc.accountID === evalActiveAccountId
      );
      try {
        const [lettingSummary, lettingConfig] = await Promise.all([
          fetchLettingSummary(evalActiveAccountId),
          fetchWrapper(
            withApiBaseUrl(
              `/letting/letting-config?name=discretionaryAgeForLetting`
            ),
            {
              method: 'GET',
              credentials: 'include',
            },
            false
          ),
        ]);

        const maxVanAge =
          lettingConfig?.find(
            (entry: LettingConfigEntry) =>
              entry.name === 'discretionaryAgeForLetting'
          )?.value || 10;

        setLettingInformation({
          fixedOrFlexi: lettingSummary?.fixedOrFlexi || null,
          eligbleToLet: isEligibleToLetWithHaven(account, maxVanAge),
        });
      } catch (error) {
        console.error(error);

        setLettingInformation({
          fixedOrFlexi: null,
          eligbleToLet: null,
        });
      }
    },
    [setLettingInformation]
  );

  const fetchUser = useCallback(
    async (disableCache = false) => {
      try {
        const user = await fetchWrapper(
          withAccountApiBaseUrl(`/owner${disableCache ? '?cache=false' : ''}`),
          {
            method: 'GET',
            credentials: 'include',
          },
          false
        );
        if (user?.accounts?.length) {
          const evalActiveAccountId = evalActiveAccountCookie(user.accounts);
          setActiveAccountId(evalActiveAccountId);
          setActiveAccountCookie(
            evalActiveAccountId,
            PUBLIC_OWNERS_ACTIVE_ACCOUNT_COOKIE_DOMAIN || ''
          );

          await fetchAndSetLettingInformation(user, evalActiveAccountId);
        } else {
          setIsAccountMissing(true);
        }
        setUser({
          ...user,
          userType: 'USER',
        });
      } catch (error) {
        if (error.statusCode !== 401) return redirect(router, '/error');
        if (availableForGuests) return setIsAnonymus(true);
        return redirect(router);
      }
    },
    [
      availableForGuests,
      router,
      setActiveAccountId,
      setUser,
      fetchAndSetLettingInformation,
    ]
  );

  useEffect(() => {
    const isGuest = isGuestCookie();
    if (!userType && !isGuest && router.pathname !== '/error') fetchUser();
    if (!userType && isGuest)
      setUser({
        userType: 'GUEST',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  useEffect(() => {
    if (shouldRefetchUser) {
      fetchUser(true);
      refetchUser(false);
    }
  }, [fetchUser, shouldRefetchUser, refetchUser]);

  if (!availableForGuests && userType !== 'USER') {
    if (userType === 'GUEST') {
      if (REDIRECT_TARGETS[router.pathname]) {
        router.push(REDIRECT_TARGETS[router.pathname]);
      }
      return (
        <MainLayout>
          <AuthPageAlert loginUrl={PUBLIC_LOGIN_URL || ''} />
        </MainLayout>
      );
    }
    return <Loading />;
  }

  if (userType === 'USER' && isAccountMissing) {
    return (
      <MainLayout>
        <MissingAccount />
      </MainLayout>
    );
  }

  if (!userType && !isAnonymus && router.pathname !== '/error') {
    return <Loading />;
  }

  return children;
};
