import { useMemo } from 'react';
import useSWR from 'swr';
import useSWRImmutable from 'swr';

import { swrFetcher, swrLocalFetcher } from '../helpers/fetch';
import { addIcons } from '../helpers/onboardingTooltip';
import { OnboardingTooltip, TooltipType } from '../types/onboardingTooltip';

type Response = {
  tooltip?: OnboardingTooltip;
};

const useOnboardingTooltips = (type: TooltipType): Response => {
  const { data: onboardingTooltips } = useSWRImmutable<
    OnboardingTooltip[],
    Error
  >('/contentful/onboarding-tooltips', swrLocalFetcher);
  const { data: seenOnboardinTooltipIDs } = useSWR<string[], Error>(
    '/contentful/seen-onboarding-tooltips',
    swrFetcher
  );
  const seenOnboardinTooltips = useMemo(
    () =>
      onboardingTooltips?.filter(
        ({ id }) => !seenOnboardinTooltipIDs?.includes(id)
      ),
    [onboardingTooltips, seenOnboardinTooltipIDs]
  );
  const tooltip = useMemo(() => {
    if (sessionStorage.getItem(type)) return;
    const tmpTooltip = seenOnboardinTooltips?.find((tt) => tt.type === type);
    if (type === TooltipType.CALENDAR_EVENT_AMEND && tmpTooltip) {
      return {
        ...tmpTooltip,
        text: addIcons(tmpTooltip.text),
      };
    }
    return tmpTooltip;
  }, [seenOnboardinTooltips, type]);

  return {
    tooltip,
  };
};

export default useOnboardingTooltips;
