import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LoadingIndicator } from '@havenengineering/module-shared-library/dist/components/LoadingIndicator';
import SelectInput from '@havenengineering/module-shared-library/dist/form/components/SelectInput';
import { Button } from '@havenengineering/module-shared-owners-ui/dist/components/Button';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';
import { DateTime } from 'luxon';

import { handleEditBookingOnCalendarGTM } from '../../helpers/googleTag';
import { LabelBadge } from '../EventCalendarApendix/LabelBadge';
import {
  fetchSwappableBreaks,
  getBreakDaysTypeLabelText,
  getBreakTypesLabelText,
  getPeakTypeLabelText,
  sendBreakSwap,
  SwappableBreak,
  SwappableBreaksResponse,
} from '../helpers/bookings';
import styles from './LetWithHavenEdit.module.scss';

interface LetWithHavenBookingEditProps {
  booking: BreakDataResponse;
  onError: (message: string) => void;
  onSave: () => void;
  onCancel: () => void;
  setCalendarLoading: (isLoading: boolean) => void;
  allPeakDatesData: PeakDatesData[];
}

export const LetWithHavenBookingEdit: FunctionComponent<
  LetWithHavenBookingEditProps
> = ({
  booking,
  onError,
  onSave,
  onCancel,
  setCalendarLoading,
  allPeakDatesData,
}) => {
  const { activeAccount } = useAuthContext();
  const [swappableBreaks, setSwappableBreaks] =
    useState<SwappableBreaksResponse>();
  const [newPackageId, setNewPackageId] = useState<string>();
  const [selectedBreakToSwap, setSelectedBreakToSwap] =
    useState<SwappableBreak | null>();
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);

  const swappableBreakOptions = useMemo(() => {
    if (!swappableBreaks) {
      return [];
    }

    return Object.keys(swappableBreaks).map((month) => ({
      label: month,
      options: swappableBreaks[month].map((swappableBreak) => ({
        label: `${DateTime.fromISO(swappableBreak.startDate).toFormat(
          'ccc dd LLL'
        )} - ${DateTime.fromISO(swappableBreak.endDate).toFormat(
          'ccc dd LLL yyyy'
        )}`,
        value: `${swappableBreak.packageId}`,
      })),
    }));
  }, [swappableBreaks]);

  const getSwappableBreaks = useCallback(async () => {
    if (!activeAccount?.accountID) {
      return;
    }

    try {
      const response = await fetchSwappableBreaks(
        activeAccount?.accountID,
        booking.startDate
      );

      setSwappableBreaks(response || {});
    } catch (error) {
      console.error(error);
      onError('Something went wrong!');
      setSwappableBreaks({});
    }
  }, [activeAccount?.accountID, booking.startDate, onError]);

  useEffect(() => {
    getSwappableBreaks();
  }, [getSwappableBreaks]);

  const swapBreaks = useCallback(async () => {
    if (!activeAccount?.accountID || !newPackageId || !booking.packageId) {
      return;
    }

    try {
      setCalendarLoading(true);
      setIsSaveDisabled(true);
      await sendBreakSwap(
        activeAccount?.accountID,
        booking.packageId,
        +newPackageId
      );

      setCalendarLoading(false);
      setIsSaveDisabled(false);
      onSave();
      handleEditBookingOnCalendarGTM(selectedBreakToSwap!);
    } catch (error) {
      console.error(error);
      onError('Something went wrong!');
      setCalendarLoading(false);
      setIsSaveDisabled(false);
    }
  }, [
    selectedBreakToSwap,
    activeAccount?.accountID,
    newPackageId,
    setCalendarLoading,
    booking,
    onSave,
    onError,
  ]);

  const getswappableBreakByPackageId = (breakPackageId: string) => {
    if (!swappableBreaks || !breakPackageId) {
      return null;
    }

    return Object.keys(swappableBreaks)
      .flatMap((month) => swappableBreaks[month])
      .find(({ packageId }) => packageId === +breakPackageId);
  };

  const handleSwappableBreakChange = (breakPackageId: string) => {
    setNewPackageId(breakPackageId);
    const swappableBreak = getswappableBreakByPackageId(breakPackageId);
    setSelectedBreakToSwap(swappableBreak);
  };

  if (!swappableBreaks) {
    return (
      <div className={styles.loadingIndicator}>
        <LoadingIndicator loading={true} />
      </div>
    );
  }

  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabSection}>
        <div className={styles.firstSection}>
          <SelectInput
            id="break-to-swap"
            label="When?"
            options={swappableBreakOptions}
            value={newPackageId || ''}
            onChange={(value) => {
              handleSwappableBreakChange(value!);
            }}
          />
          <div className={styles.previewTitle}>Preview:</div>
        </div>
        <hr style={{ opacity: 0.3 }} />
        {selectedBreakToSwap ? (
          <div className={styles.previewDetails}>
            <LabelBadge
              value={selectedBreakToSwap.value}
              code={booking.status.code}
              label={'HAVEN'}
            />
            <div className={styles.dates}>{`${DateTime.fromISO(
              selectedBreakToSwap.startDate
            ).toFormat('ccc dd LLL')} - ${DateTime.fromISO(
              selectedBreakToSwap.endDate
            ).toFormat('ccc dd LLL yyyy')}`}</div>
            <div className={styles.numOfNights}>
              {`${getPeakTypeLabelText(
                selectedBreakToSwap.breakPeakType
              )} (${getBreakDaysTypeLabelText(
                selectedBreakToSwap.breakDaysType
              )})`}
            </div>
          </div>
        ) : (
          <div className={styles.previewDetails}>
            <LabelBadge
              value={booking.value}
              code={booking.status.code}
              label={'Haven'}
            />
            <div className={styles.dates}>{`${DateTime.fromISO(
              booking.startDate
            ).toFormat('ccc dd LLL')} - ${DateTime.fromISO(
              booking.endDate
            ).toFormat('ccc dd LLL yyyy')}`}</div>
            <div className={styles.numOfNights}>
              {getBreakTypesLabelText(
                booking.startDate,
                booking.endDate,
                allPeakDatesData
              )}
            </div>
          </div>
        )}
      </div>
      <div className={styles.tabSection}>
        <hr style={{ opacity: 0.3 }} />
        <div className={styles.actionContainer}>
          <Button
            variant="outlined"
            style={{ marginRight: '16px' }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={!newPackageId || isSaveDisabled}
            onClick={swapBreaks}
            data-testid="save-changes-button"
          >
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
};
