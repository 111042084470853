import React, { useState } from 'react';
import InlineMessaging, {
  InlineMessagingTheme,
} from '@havenengineering/module-shared-library/dist/components/InlineMessaging/InlineMessaging';
import { Button } from '@havenengineering/module-shared-owners-ui/dist/components/Button';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';

import { CounterButtons } from '../CounterButtons/CounterButtons';
import { upsertParticipants } from '../helpers/bookings';
import { useBooking } from './bookingContext';
import styles from './ParticipantEditor.module.scss';
import ParticipantForm from './ParticipantForm';

const ParticipantEditor = () => {
  const { activeAccount } = useAuthContext();
  const { booking, handleUpsertParticipants } = useBooking();

  const [selectedForEdit, setSelectedForEdit] = useState<number>(-1);
  const [showForm, setShowForm] = useState(false);
  const [participants, setParticipants] = useState<Participant[]>(
    booking.users
  );
  const [childNumber, setChildNumber] = useState(booking.childNumber);
  const [toddlersNumber, setToddlersNumber] = useState(booking.toddlersNumber);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSave = async () => {
    setError('');
    setSuccess(false);
    setLoading(true);
    const body = {
      additionalGuests: participants.map(({ id, name, phoneNumber }) => ({
        id,
        name,
        phoneNumber,
      })),
      childNumber,
      toddlersNumber,
    };
    try {
      await upsertParticipants(body, booking, activeAccount);
      handleUpsertParticipants(body);
      setSuccess(true);
    } catch (error) {
      setError('Something went wrong, please try again');
    } finally {
      setLoading(false);
    }
  };

  const handleParticipantSave = (participant: Participant) => {
    setParticipants((prev) => [...prev, participant]);
    setShowForm(false);
  };

  const handleParticipantEdit = (participant: Participant) => {
    setParticipants((prev) => {
      const b = [...prev];
      b[selectedForEdit] = participant;
      return b;
    });
    setSelectedForEdit(-1);
  };

  const handleDeleteParticipant = (index: number) => {
    setParticipants((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const handleCancelEdit = () => {
    setSelectedForEdit(-1);
    setShowForm(false);
  };

  return (
    <div className={styles.editorContainer} data-testid="participants-editor">
      <div className={styles.counter}>
        <p>
          <strong>Adults</strong>
          <br />
          <small>16+ yrs</small>
        </p>
        <Button
          className={styles.iconButton}
          variant="outlined"
          onClick={() => setShowForm(true)}
          type="button"
          disabled={selectedForEdit >= 0}
          data-testid="add-adult-btn"
        >
          <img
            src="https://www.haven.com/assets/icons/plus.svg"
            width={24}
            height={24}
            alt="add"
          />
        </Button>
      </div>
      {participants.map((participant, idx) => (
        <div
          key={`${participant.name}-${participant.phoneNumber}`}
          className={styles.userRow}
        >
          <p>
            <strong>{participant.name}</strong>
            <br />
            <small>{participant.phoneNumber}</small>
          </p>
          <div>
            <Button
              className={styles.iconButton}
              variant="outlined"
              disabled={showForm}
              onClick={() => handleDeleteParticipant(idx)}
              type="button"
              data-testid={`delete-${idx}`}
            >
              <img
                src="/assets/icon-delete.svg"
                width={24}
                height={24}
                alt="add"
              />
            </Button>
            <Button
              className={styles.iconButton}
              variant="outlined"
              disabled={showForm}
              onClick={() => setSelectedForEdit(idx)}
              type="button"
              data-testid={`edit-${idx}`}
            >
              <img
                src="https://www.haven.com/assets/icons/pencil-blue.svg"
                width={24}
                height={24}
                alt="add"
              />
            </Button>
          </div>
        </div>
      ))}
      {selectedForEdit >= 0 && (
        <ParticipantForm
          participant={participants[selectedForEdit]}
          handleSaveParticipant={handleParticipantEdit}
          handleCancel={() => handleCancelEdit()}
        />
      )}
      {showForm && (
        <ParticipantForm
          handleSaveParticipant={handleParticipantSave}
          handleCancel={() => handleCancelEdit()}
        />
      )}

      <hr className={styles.divider} />
      <InlineMessaging
        type={InlineMessagingTheme.INFO_THEME}
        title="Info"
        message="We do not require any guest details for under 16 year olds"
        dismissible={false}
      />
      <div className={styles.counter}>
        <p>
          <strong>Children</strong>
          <br />
          <small>4 - 16 year olds</small>
        </p>
        <CounterButtons
          count={childNumber}
          onDecrement={(count) => setChildNumber(count)}
          onIncrement={(count) => setChildNumber(count)}
          testId="children"
        />
      </div>

      <hr className={styles.divider} />
      <div className={styles.counter}>
        <p>
          <strong>Infants</strong>
          <br />
          <small>0 - 4 year olds</small>
        </p>
        <CounterButtons
          count={toddlersNumber}
          onDecrement={(count) => setToddlersNumber(count)}
          onIncrement={(count) => setToddlersNumber(count)}
          testId="toddlers"
        />
      </div>

      {error && (
        <InlineMessaging
          type={InlineMessagingTheme.ERROR_THEME}
          title="Error"
          message={error}
          dismissible={false}
        />
      )}

      <Button
        fullWidth
        className={styles.saveButton}
        isLoading={loading}
        type="submit"
        onClick={() => handleSave()}
      >
        Save Participants
      </Button>
      {success && (
        <InlineMessaging
          type={InlineMessagingTheme.SUCCESS_THEME}
          title="Success"
          message="Update was successful"
          dismissible={false}
        />
      )}
    </div>
  );
};

export default ParticipantEditor;
