import { EventData } from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar';
import {
  getEventDays,
  getEventDaysWithoutConnector,
  isPast,
} from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar/utils';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

import {
  ArrivalDataEnum,
  ParkClosedDataTypeEnum,
  StyleTypes,
} from '../pages/bookings';
import { BreakVisitType } from '../types/lettings';

export const CANCEL_ARRIVAL_ERROR_MESSAGE =
  'We’re sorry we’ve not been able to cancel the selected booking.';
export const REMOVE_BREAK_ERROR_MESSAGE =
  'We’re sorry we’ve not been able to remove to Let with Haven for the selected break.';
export const PARK_CLOSED_INFO_MESSAGE =
  'Sadly, you can’t let your Haven holiday home year-round as the park is closed. Check your calendar for when we open.';
export const REMOVE_LET_TIME_ERROR_MESSAGE =
  'We’re sorry we’ve not been able to remove the selected dates, as they’re let with Haven and booking amendments have now passed.';
export const REMOVE_LET_2_OWN_BELOW_MINIMUM_ERROR_MESSAGE =
  'We’re sorry we’ve not been able to remove the selected booking below the contracted minimum.';

enum BreakDataTypeEnum {
  STATUS_NOT_SET = 0,
  LET_GUARANTEED = 1,
  LET_NOT_GUARANTEED = 2,
  // temporary - until confirmed Haven Let feature is available
  LET_CONFIRMED = 1122,
  OWNER_USING = 4,
  UNDER_REPAIR = 8,
  LET_GUARANTEED__UNDER_REPAIR = 9,
  LET_NOT_GUARANTEED__UNDER_REPAIR = 10,
  OWNER_USING__UNDER_REPAIR = 12,
  OWNER_NOT_USING = 16,
  LET_GUARANTEED__OWNER_NOT_USING = 17,
  LET_NOT_GUARENTEED__OWNER_NOT_USING = 18,
  UNDER_REPAIR__OWNER_NOT_USING = 24,
  WAITLIST = 32,
  OWNER_NOT_USING__WAITLIST = 48,
  CAP_REACHED = 64,
  LET_GUARANTEED__CAP_REACHED = 65,
  LET_NOT_GUARANTEED__CAP_REACHED = 66,
  OWNER_USING__CAP_REACHED = 68,
  WAITLIST__CAP_REACHED = 98,
  OWNER_USING__WAITLIST__CAP_REACHED = 100,
  OWNER_NOT_USING__WAITLIST__CAP_REACHED = 112,
  OWNER_MAY_OR_MAY_NOT_USING__WAITLIST__CAP_REACHED = 116,
  AVAILABILITY_WITHIN_THE_CAP = 128,
  LET_GUARANTEED__AVAILABILITY_WITHIN_THE_CAP = 129,
  LET_NOT_GUARANTEED__AVAILABILITY_WITHIN_THE_CAP = 130,
  OWNER_USING__AVAILABILITY_WITHIN_THE_CAP = 132,
  LET_GUARANTEED__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP = 193,
  LET_NOT_GUARANTEED__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP = 194,
  OWNER_USING__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP = 196,
  WAITLIST__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP = 224,
  OWNERS_USING_WAITLIST__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP = 228,
  LET_GURANTEED__UNDER_REPAIR__CAP_REACHED = 73,
  LET_NOT_GUARANTEED__UNDER_REPAIR__CAP_REACHED = 74,
  OWNER_USING__UNDER_REPAIR__CAP_REACHED = 76,
  OWNER_USING__OWNER_NOT_USING__CAP_REACHED = 84,
  OWNER_USING__UNDER_REPAIR__OWNER_NOT_USING = 124,
  OWNER_USING__UNDER_REPAIR__AVAILABILITY_WITHIN_THE_CAP = 140,
  UNDER_REPAIR__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP = 201,
  OWNER_USING__UNDER_REPAIR__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP = 204,
  OWNER_USING__OWNER_NOT_USING__WAITLIST__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP = 244,
  PART_EXCHANGE = 256,
  OWNER_USING__PART_EXCHANGE = 260,
  UNDER_REPAIR_LABEL__PART_EXCHANGE = 264,
  OWNER_USING__UNDER_REPAIR__PART_EXCHANGE = 268,
  OWNER_NOT_USING__PART_EXCHANGE = 272,
  UNDER_REPAIR__OWNER_NOT_USING__PART_EXCHANGE = 280,
  CAP_REACHED__PART_EXCHANGE = 320,
  OWNER_USING__CAP_REACHED__PART_EXCHANGE = 324,
  OWNER_USING__UNDER_REPAIR__CAP_REACHED__PART_EXCHANGE = 332,
  OWNER_USING__OWNER_NOT_USING__CAP_REACHED__PART_EXCHANGE = 340,
  OWNER_USING__UNDER_REPAIR__OWNER_NOT_USING__PART_EXCHANGE = 380,
  OWNER_USING__AVAILABILITY_WITHIN_THE_CAP__PART_EXCHANGE = 388,
  AVAILABILITY_WITHIN_THE_CAP__PART_EXCHANGE = 384,
  OWNER_USING__UNDER_REPAIR__AVAILABILITY_WITHIN_THE_CAP__PART_EXCHANGE = 396,
  OWNER_USING__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP__PART_EXCHANGE = 452,
  OWNER_USING__UNDER_REPAIR__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP__PART_EXCHANGE = 460,
}

// LET_GUARANTEED || LET_NOT_GUARANTEED
export const isLetWithHaven = (code: number | undefined) =>
  !!code &&
  [
    BreakDataTypeEnum.LET_GUARANTEED,
    BreakDataTypeEnum.LET_GUARANTEED__UNDER_REPAIR,
    BreakDataTypeEnum.LET_GUARANTEED__OWNER_NOT_USING,
    BreakDataTypeEnum.LET_GUARANTEED__CAP_REACHED,
    BreakDataTypeEnum.LET_GUARANTEED__AVAILABILITY_WITHIN_THE_CAP,
    BreakDataTypeEnum.LET_GUARANTEED__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP,
    BreakDataTypeEnum.LET_NOT_GUARANTEED,
    BreakDataTypeEnum.LET_NOT_GUARANTEED__UNDER_REPAIR,
    BreakDataTypeEnum.LET_NOT_GUARENTEED__OWNER_NOT_USING,
    BreakDataTypeEnum.LET_NOT_GUARANTEED__CAP_REACHED,
    BreakDataTypeEnum.LET_NOT_GUARANTEED__AVAILABILITY_WITHIN_THE_CAP,
    BreakDataTypeEnum.LET_NOT_GUARANTEED__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP,
    BreakDataTypeEnum.LET_GURANTEED__UNDER_REPAIR__CAP_REACHED,
    BreakDataTypeEnum.LET_NOT_GUARANTEED__UNDER_REPAIR__CAP_REACHED,
  ].includes(code);

// WAITLIST && !(LET_GUARANTEED || LET_NOT_GUARANTEED)
export const isWaitList = (code: number | undefined) =>
  !!code &&
  [
    BreakDataTypeEnum.WAITLIST,
    BreakDataTypeEnum.WAITLIST__CAP_REACHED,
    BreakDataTypeEnum.WAITLIST__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP,
    BreakDataTypeEnum.OWNER_NOT_USING__WAITLIST,
    BreakDataTypeEnum.OWNER_NOT_USING__WAITLIST__CAP_REACHED,
    BreakDataTypeEnum.OWNER_USING__WAITLIST__CAP_REACHED,
    BreakDataTypeEnum.OWNER_MAY_OR_MAY_NOT_USING__WAITLIST__CAP_REACHED,
    BreakDataTypeEnum.OWNERS_USING_WAITLIST__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP,
    BreakDataTypeEnum.OWNER_USING__OWNER_NOT_USING__WAITLIST__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP,
  ].includes(code);

// (OWNER_USING || OWNER_NOT_USING) && !(LET_GUARANTEED || LET_NOT_GUARANTEED || WAITLIST)
export const isOffer = (code: number | undefined) =>
  !!code &&
  [
    BreakDataTypeEnum.OWNER_USING,
    BreakDataTypeEnum.OWNER_USING__CAP_REACHED,
    BreakDataTypeEnum.OWNER_USING__UNDER_REPAIR,
    BreakDataTypeEnum.OWNER_NOT_USING,
    BreakDataTypeEnum.UNDER_REPAIR__OWNER_NOT_USING,
    BreakDataTypeEnum.CAP_REACHED,
    BreakDataTypeEnum.OWNER_USING__AVAILABILITY_WITHIN_THE_CAP,
    BreakDataTypeEnum.OWNER_USING__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP,
    BreakDataTypeEnum.OWNER_USING__OWNER_NOT_USING__CAP_REACHED,
  ].includes(code);

// UNDER_REPAIR
const isMaintenance = (code: number | undefined) =>
  !!code &&
  [
    BreakDataTypeEnum.UNDER_REPAIR,
    BreakDataTypeEnum.LET_GUARANTEED__UNDER_REPAIR,
    BreakDataTypeEnum.LET_NOT_GUARANTEED__UNDER_REPAIR,
    BreakDataTypeEnum.OWNER_USING__UNDER_REPAIR,
    BreakDataTypeEnum.UNDER_REPAIR__OWNER_NOT_USING,
    BreakDataTypeEnum.LET_GURANTEED__UNDER_REPAIR__CAP_REACHED,
    BreakDataTypeEnum.LET_NOT_GUARANTEED__UNDER_REPAIR__CAP_REACHED,
    BreakDataTypeEnum.OWNER_USING__UNDER_REPAIR__CAP_REACHED,
    BreakDataTypeEnum.OWNER_USING__UNDER_REPAIR__OWNER_NOT_USING,
    BreakDataTypeEnum.OWNER_USING__UNDER_REPAIR__AVAILABILITY_WITHIN_THE_CAP,
    BreakDataTypeEnum.UNDER_REPAIR__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP,
    BreakDataTypeEnum.OWNER_USING__UNDER_REPAIR__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP,
  ].includes(code);

// UNAVAILABLE
export const isUnavailable = (code: number | undefined) =>
  !!code &&
  [
    BreakDataTypeEnum.PART_EXCHANGE,
    BreakDataTypeEnum.OWNER_USING__PART_EXCHANGE,
    BreakDataTypeEnum.UNDER_REPAIR_LABEL__PART_EXCHANGE,
    BreakDataTypeEnum.OWNER_USING__UNDER_REPAIR__PART_EXCHANGE,
    BreakDataTypeEnum.OWNER_NOT_USING__PART_EXCHANGE,
    BreakDataTypeEnum.UNDER_REPAIR__OWNER_NOT_USING__PART_EXCHANGE,
    BreakDataTypeEnum.CAP_REACHED__PART_EXCHANGE,
    BreakDataTypeEnum.OWNER_USING__CAP_REACHED__PART_EXCHANGE,
    BreakDataTypeEnum.OWNER_USING__UNDER_REPAIR__CAP_REACHED__PART_EXCHANGE,
    BreakDataTypeEnum.OWNER_USING__OWNER_NOT_USING__CAP_REACHED__PART_EXCHANGE,
    BreakDataTypeEnum.OWNER_USING__UNDER_REPAIR__OWNER_NOT_USING__PART_EXCHANGE,
    BreakDataTypeEnum.AVAILABILITY_WITHIN_THE_CAP__PART_EXCHANGE,
    BreakDataTypeEnum.OWNER_USING__UNDER_REPAIR__AVAILABILITY_WITHIN_THE_CAP__PART_EXCHANGE,
    BreakDataTypeEnum.OWNER_USING__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP__PART_EXCHANGE,
    BreakDataTypeEnum.OWNER_USING__UNDER_REPAIR__CAP_REACHED__AVAILABILITY_WITHIN_THE_CAP__PART_EXCHANGE,
    BreakDataTypeEnum.OWNER_USING__AVAILABILITY_WITHIN_THE_CAP__PART_EXCHANGE,
  ].includes(code);

export const getBreakVisitType = (code: number | undefined) => {
  if (code === 1122 || isLetWithHaven(code)) {
    return BreakVisitType.LET_WITH_HAVEN;
  }

  if (isWaitList(code)) {
    return BreakVisitType.WAITLIST;
  }

  if (isMaintenance(code)) {
    return BreakVisitType.MAINTENANCE;
  }

  if (code === ArrivalDataEnum.ARRIVAL_BOOKING) {
    return BreakVisitType.OWNER_OR_PRIVATE;
  }

  return BreakVisitType.NONE;
};

export const getArrivalEventData = (
  bookings: ArrivalBooking[],
  loggedInUserName?: string
): EventData<ArrivalBooking>[] =>
  bookings.map((booking) => ({
    id: booking.shortCode,
    days: getEventDays(
      DateTime.fromISO(booking.dateArrival),
      DateTime.fromISO(booking.dueToLeaveDate)
    ),
    data: booking,
    styleType: StyleTypes.NORMAL as const,
    code: ArrivalDataEnum.ARRIVAL_BOOKING, // placeholder
    label: booking.isLetting
      ? booking.firstName || 'private let'
      : loggedInUserName === booking.name
      ? 'you'
      : booking.name || 'you',
    order: 4,
    bannerStyles: {
      color: 'white',
      backgroundColor: '#29822F',
      borderColor: '#29822F',
    },
  }));

// Breakdata - calendar event mapping version 1
// sometimes the packageId is null (probably a bug from plot)
// as a temp solution generate an uuid instead
export const getBreakEventData = (
  breakData: BreakDataResponse[],
  allowedToLet: boolean
) => {
  const partExEventData: { startDate: string; endDate: string }[] = [];
  const breakEventData: EventData<BreakDataResponse>[] = [];

  breakData.forEach((data) => {
    const {
      packageId,
      startDate,
      endDate,
      value,
      status: { code, restrictedDate },
    } = data;

    // confirmed LET WITH HAVEN event
    // if LET GUARANTED is present. Haven letting must be displayed
    if (isLetWithHaven(code)) {
      breakEventData.push({
        data,
        code,
        id: `${packageId || uuid()}-${code}-letGuaranteed`,
        days: getEventDays(
          DateTime.fromISO(startDate),
          DateTime.fromISO(endDate)
        ),
        styleType: StyleTypes.NORMAL as const,
        label: 'haven',
        order: 2,
        bannerStyles: {
          color: '#031545',
          backgroundColor: '#E5E7EC',
          borderColor: '#031545',
          borderStyle: 'dashed',
        },
      });
    }

    // WAITLIST event
    // WAITLIST/CAP_REACHED is present then the on waitlist event must be displayed
    if (isWaitList(code)) {
      breakEventData.push({
        data,
        code,
        value,
        id: `${packageId || uuid()}-${code}-onWaitlist`,
        days: getEventDays(
          DateTime.fromISO(startDate),
          DateTime.fromISO(endDate)
        ),
        styleType: StyleTypes.NORMAL as const,
        label: 'on waitlist',
        order: 3,
        bannerStyles: {
          color: '#031545',
          borderStyle: 'dashed',
          backgroundColor: '#FFFBF3',
          borderColor: '#FFBB13',
        },
      });
    }

    // OWNER_USING event
    // due to a bug in PLOT OWNER_USING is OWNER_NOT_USING
    // in the case the offer event must be displayed
    if (isOffer(code) && allowedToLet) {
      // no need to display this type of event in the past
      if (isPast(DateTime.fromISO(startDate))) {
        return;
      }

      // do not display offers with 0 value
      if ((!value || parseFloat(value) <= 0) && !restrictedDate) {
        return;
      }

      breakEventData.push({
        data,
        code,
        id: `${packageId || uuid()}-${code}-offer`,
        days: getEventDays(
          DateTime.fromISO(startDate),
          DateTime.fromISO(endDate)
        ),
        styleType: StyleTypes.DASHED as const,
        value: restrictedDate ? 'Sold out' : getFormattedOfferValue(value),
        label: '',
        order: 5,
      });
    }

    // UNDER REPAIR event
    // UNDER REPAIR event must be present. I can be safely displayed with other events.
    if (isMaintenance(code)) {
      breakEventData.push({
        data,
        code,
        value,
        id: `${packageId || uuid()}-${code}`,
        days: getEventDays(
          DateTime.fromISO(startDate),
          DateTime.fromISO(endDate)
        ),
        styleType: StyleTypes.IMAGE as const,
        label: '',
        order: 6,
        bannerStyles: {
          imgUrl: '/assets/icon_spanner_blue.svg',
        },
      });
    }

    // UNAVAILABLE event
    if (isUnavailable(code)) {
      partExEventData.push({
        startDate: data.startDate,
        endDate: data.endDate,
      });
    }
  });

  return { breakEventData, partExEventData };
};

const getFormattedOfferValue = (value: number | string | undefined) => {
  if (!value) {
    return;
  }
  const stringifiedValue = value.toString();
  const hasDash = stringifiedValue.includes('-');
  if (hasDash) {
    const dashIndex = stringifiedValue.indexOf('-');
    const afterDashIndex = dashIndex + 1;
    return `£${stringifiedValue.substring(
      0,
      afterDashIndex
    )}£${stringifiedValue.substring(afterDashIndex)}`;
  }
  return `£${stringifiedValue}`;
};

export const getParkClosedEventData = (parkClosedData: ParkClosedData[]) =>
  parkClosedData.map((park) => {
    /**
     * use open interval for park closed data
     * closingDate: last day of the season
     * openingDate: first day of the season
     */
    const startDate = DateTime.fromFormat(park.closingDate, 'yyyy-MM-dd').plus({
      days: 1,
    });
    const endDate = DateTime.fromFormat(park.openingDate, 'yyyy-MM-dd').minus({
      days: 1,
    });

    return {
      id: `park-closed-${uuid()}`,
      data: {
        startDate,
        endDate,
      },
      days: getEventDays(startDate, endDate),
      styleType: StyleTypes.NORMAL as const,
      code: ParkClosedDataTypeEnum.PARK_CLOSED,
      label: 'Park closed',
      order: 1,
      bannerStyles: {
        color: 'white',
        borderColor: '#E0004D',
        backgroundColor: '#E0004D',
      },
    };
  });

const createParkExObject = (start: string, end: string) => {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);

  const labelStartDate = startDate.toFormat('ccc, d LLL');
  const labelEndDate = endDate.toFormat('ccc, d LLL yyyy');

  return {
    id: `part-ex-${uuid()}`,
    data: {
      startDate,
      endDate,
    },
    days: getEventDays(startDate, endDate),
    styleType: StyleTypes.PART_EX as const,
    code: BreakDataTypeEnum.PART_EXCHANGE,
    label: `${labelStartDate}–${labelEndDate}`,
    order: 7,
  };
};
export const getParkExEventData = (
  partExEventData: { startDate: string; endDate: string }[]
) => {
  if (partExEventData.length === 0) return [];

  partExEventData.sort(
    (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
  );

  const mergedEvents = [];

  let currentEvent = {
    startDate: partExEventData[0].startDate,
    endDate: partExEventData[0].endDate,
  };

  for (let i = 1; i < partExEventData.length; i++) {
    const event = partExEventData[i];
    const { startDate, endDate } = event;

    if (currentEvent.endDate === startDate) {
      currentEvent.endDate = endDate;
    } else {
      mergedEvents.push(
        createParkExObject(currentEvent.startDate, currentEvent.endDate)
      );
      currentEvent = {
        startDate,
        endDate,
      };
    }
  }

  mergedEvents.push(
    createParkExObject(currentEvent.startDate, currentEvent.endDate)
  );

  return mergedEvents;
};

export const getPeakDatesEventData = (
  peakDates: PeakDatesData[],
  superPeak = false,
  isMobile = false
): EventData<PeakDatesData>[] =>
  peakDates.map((peakDate) => ({
    id: `${peakDate.packageId || uuid()}-peak`,
    days: getEventDaysWithoutConnector(
      DateTime.fromISO(peakDate.startDate),
      DateTime.fromISO(peakDate.endDate)
    ),
    styleType: StyleTypes.DAY_STYLES_ONY as const,
    dayStyles: !superPeak
      ? {
          background: '#f0f9fc',
        }
      : {
          background: `linear-gradient(
        to bottom, 
        #dbf0f9 0%,
        #dbf0f9 ${isMobile ? '40' : '50'}%,
        #f0f9fc ${isMobile ? '40' : '50'}%,
        #f0f9fc 100%
      )`,
        },
    order: superPeak ? 7 : 8,
  }));

export const getlabelBadgeStyleByCode = (code: number | undefined) => {
  if (!code) {
    return null;
  }

  // temporary - until confirmed Haven Let feature is available
  if (code === 1122) {
    return 'letWithHavenConfirmed';
  }

  if (isLetWithHaven(code)) {
    return 'letWithHaven';
  }
  if (isMaintenance(code)) {
    return 'maintenance';
  }
  if (isOffer(code)) {
    return 'offer';
  }
  if (isWaitList(code)) {
    return 'waitlist';
  }
  if (code === ParkClosedDataTypeEnum.PARK_CLOSED) {
    return 'closed';
  }
  if (code === ArrivalDataEnum.ARRIVAL_BOOKING) {
    return 'arrivalBooking';
  }

  return null;
};

export const isEligibleToLetWithHaven = (
  account: UserAccount,
  maximumVanAge: number
) => {
  if (account?.acceptedForLet) {
    return true;
  }

  return (
    +(account?.vanYear || 0) >
    DateTime.now()?.minus({ years: maximumVanAge })?.year
  );
};

export const isEligibleToLetWithHavenNextSeason = (
  account: UserAccount,
  maximumVanAge: number
) => {
  return (
    account?.acceptedForLet &&
    +(account?.vanYear || 0) >
      DateTime.now()?.plus({ years: 1 }).minus({ years: maximumVanAge })?.year
  );
};

export const hasPlayPassTrial = (parkCode?: string) => {
  if (!parkCode) return false;
  const PlayPassTrialParks = [
    'SA',
    'CT',
    'HA',
    'LS',
    'DE',
    'PV',
    'MM',
    'HM',
    'LY',
    'BR',
    'PH',
    'CC',
    'TP',
    'GS',
    'RE',
    'LA',
    'RP',
    'KP',
    'CG',
    'AH',
    'TW',
    'WM',
    'OR',
    'HO',
    'WD',
    'GR',
    'BE',
    'SE',
    'DF',
    'QW',
    'CF',
    'CH',
    'BD',
    'PS',
    'SN',
  ];
  return PlayPassTrialParks.includes(parkCode);
};

export const getPrivCardHolderSubmitValue = (value: string) => {
  if (!value) return '';
  return value === 'include_option_to_purchase_a_play_pass_online'
    ? 'include_option_to_purchase_a_play_pass_online'
    : 'privilege_card_holder';
};
