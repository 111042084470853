import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ConfirmationOverlay } from '@havenengineering/module-shared-owners-ui/dist/components/ConfirmationOverlay';
import { EventData } from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar';
import { isPast } from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar/utils';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';
import clsx from 'clsx';
import { DateTime } from 'luxon';

import { LettingsContext } from '../../contexts/lettings';
import { isOffer } from '../../helpers/calendar';
import { ArrivalDataEnum, ParkClosedDataTypeEnum } from '../../pages/bookings';
import { BreakVisitType } from '../../types/lettings';
import {
  fetchSwappableBreaks,
  getBreakLabel,
  SwappableBreaksResponse,
} from '../helpers/bookings';
import styles from './EventListItem.module.scss';
import { LabelBadge } from './LabelBadge';

type EventListItemProps = {
  event: EventData;
  handleEdit?: (eventSelection: EventData) => void;
  handleCancel: (eventSelection: EventData) => void;
  breakVisitType: BreakVisitType;
  isRemovalDisabled: boolean;
  allPeakDatesData: PeakDatesData[];
};

export const EventListItem: FunctionComponent<EventListItemProps> = ({
  event,
  handleEdit,
  handleCancel,
  breakVisitType,
  isRemovalDisabled,
  allPeakDatesData,
}) => {
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);

  const { lettingSummary } = useContext(LettingsContext);

  const isArrivalBooking = event.code === ArrivalDataEnum.ARRIVAL_BOOKING;
  const isParkClosedEvent = event.code === ParkClosedDataTypeEnum.PARK_CLOSED;

  const { activeAccount } = useAuthContext();
  const [swappableBreaks, setSwappableBreaks] =
    useState<SwappableBreaksResponse>({});

  const getSwappableBreaks = useCallback(async () => {
    const validLet2OwnLetWithHavenBookingToEdit =
      lettingSummary?.let2Own &&
      breakVisitType === BreakVisitType.LET_WITH_HAVEN &&
      !isArrivalBooking &&
      !event?.data?.status?.withinSixWeeks;

    if (!activeAccount?.accountID || !validLet2OwnLetWithHavenBookingToEdit) {
      return;
    }

    try {
      const response = await fetchSwappableBreaks(
        activeAccount?.accountID,
        event.data.startDate
      );

      setSwappableBreaks(response || {});
    } catch (error) {
      console.error(error);
      setSwappableBreaks({});
    }
  }, [
    activeAccount?.accountID,
    breakVisitType,
    event.data.startDate,
    event.data?.status?.withinSixWeeks,
    isArrivalBooking,
    lettingSummary?.let2Own,
  ]);

  useEffect(() => {
    getSwappableBreaks();
  }, [getSwappableBreaks]);

  const startDate = isArrivalBooking
    ? event.data.dateArrival
    : event.data.startDate;
  const endDate = isArrivalBooking
    ? event.data.dueToLeaveDate
    : event.data.endDate;

  const showDeleteIcon = useMemo((): boolean => {
    if ([BreakVisitType.LET_WITH_HAVEN].includes(breakVisitType)) {
      return !event?.data?.status?.withinSixWeeks;
    }

    return (
      isArrivalBooking ||
      (!isArrivalBooking && !isPast(DateTime.fromISO(startDate)))
    );
  }, [
    breakVisitType,
    event?.data?.status?.withinSixWeeks,
    isArrivalBooking,
    startDate,
  ]);

  const showEditButton = useMemo(() => {
    const validArrivalToEdit =
      isArrivalBooking && !(event.data as ArrivalBooking).isCancelled;

    const validLet2OwnLetWithHavenBookingToEdit =
      lettingSummary?.let2Own &&
      breakVisitType === BreakVisitType.LET_WITH_HAVEN &&
      !isArrivalBooking &&
      !event?.data?.status?.withinSixWeeks &&
      Object.keys(swappableBreaks).length > 0;

    return (
      handleEdit &&
      (validArrivalToEdit || validLet2OwnLetWithHavenBookingToEdit)
    );
  }, [
    breakVisitType,
    event.data,
    handleEdit,
    isArrivalBooking,
    lettingSummary?.let2Own,
    swappableBreaks,
  ]);

  // temp solution
  if (!event.data) {
    return (
      <div className={styles.dataLessWrapper}>
        <LabelBadge label={event.label || ''} />
      </div>
    );
  }

  const onCancel = () => {
    setCancelDialogOpen(false);
    handleCancel(event);
  };

  return (
    <>
      <ConfirmationOverlay
        isOpen={cancelDialogOpen}
        title="You're about to delete a booking!"
        content={
          <>
            <p>This action can’t be undone.</p>
            <p>
              <b>Are you sure?</b>
            </p>
          </>
        }
        handleOk={() => onCancel()}
        handleCancel={() => setCancelDialogOpen(false)}
        okLabel="Yes, proceed"
        cancelLabel="No, go back"
      />
      <div className={styles.breakContainer} key={event.id}>
        <div>
          <div className="fs-mask">
            <LabelBadge
              value={event.value}
              code={event.code}
              label={event.label || ''}
            />
          </div>
          <div className={styles.dates}>{`${DateTime.fromISO(
            startDate
          ).toFormat('ccc dd LLL')} - ${DateTime.fromISO(endDate).toFormat(
            'ccc dd LLL yyyy'
          )}`}</div>
          <div className={styles.numOfNights}>
            {getBreakLabel(
              startDate,
              endDate,
              breakVisitType,
              allPeakDatesData,
              lettingSummary?.let2Own
            )}
          </div>
          {isArrivalBooking && (
            <>
              <div className={clsx(styles.infoString, 'fs-mask')}>
                {event.data.name}
              </div>
              {(event.data as ArrivalBooking).isLetting && (
                <div className={clsx(styles.infoString, 'fs-mask')}>
                  {`${
                    event.data.users?.length +
                    event.data.toddlersNumber +
                    event.data.childNumber
                  } guest`}
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.eventActions}>
          {showEditButton && (
            <img
              className={styles.editIcon}
              src="/assets/icon-edit.svg"
              alt="edit-icon"
              onClick={() => handleEdit!(event)}
            />
          )}
          {!event.data?.cancelled &&
            !isOffer(event.code) &&
            !isParkClosedEvent &&
            showDeleteIcon && (
              <img
                className={clsx(
                  styles.deleteIcon,
                  isRemovalDisabled ? styles.disabled : ''
                )}
                src="/assets/icon-delete.svg"
                alt="delete-icon"
                onClick={() => !isRemovalDisabled && setCancelDialogOpen(true)}
              />
            )}
        </div>
      </div>
    </>
  );
};
