import { useContext } from 'react';
import { PeakType } from '@havenengineering/module-shared-owners-library/dist/enums/peakType';
import { L2oSummary } from '@havenengineering/module-shared-owners-library/dist/interfaces/l2oSummary';
import { MODE } from '@havenengineering/module-shared-owners-ui/dist/components/Let2OwnBreakSummary';

import { LettingsContext } from '../../../contexts/lettings';
import { useScreenWidth } from '../../../hooks/useScreenWidth';
import { Let2OwnBreaksSummaryWrapper } from '../Let2OwnBreakSummaryWrapper/Let2OwnBreakSummaryWrapper';
import { Let2OwnRenewalCompletedBanner } from '../Let2OwnRenewalCompleteBanner/Let2OwnRenewalCompleteBanner';
import styles from './CalendarBanner.module.scss';

interface CalendarBannerProps {
  let2OwnSummary?: L2oSummary;
  loading: boolean;
  skipPayment?: boolean;
  breaksNotSavedAlertEnabled?: boolean;
  accountId?: number;
  setLoading?: (value: boolean) => void;
}

export const CalendarBanner: React.FC<CalendarBannerProps> = ({
  let2OwnSummary,
  loading,
  skipPayment = false,
  breaksNotSavedAlertEnabled = false,
  accountId,
  setLoading,
}) => {
  const currentDate = new Date().toISOString();
  const mobileView = useScreenWidth(960);

  const { lettingSummary, isLet2OwnRenewal } = useContext(LettingsContext);

  const maxBreakList = () => {
    const getMaxBreaksForPeakType = (peakType: PeakType) => {
      if (!let2OwnSummary?.summary) return 0;

      return (
        let2OwnSummary.summary[peakType].midweek.max +
        let2OwnSummary.summary[peakType].weekend.max
      );
    };

    return (
      <div className={styles.maxBreakList}>
        <span>Maximum number of breaks:</span>
        {`${getMaxBreaksForPeakType(
          PeakType.PEAK
        )} peak, ${getMaxBreaksForPeakType(
          PeakType.OFF_PEAK
        )} off-peak, ${getMaxBreaksForPeakType(
          PeakType.SUPER_PEAK
        )} super peak`}
      </div>
    );
  };

  if (lettingSummary?.let2Own && let2OwnSummary?.summary && !loading) {
    if (isLet2OwnRenewal) {
      return (
        <>
          <Let2OwnBreaksSummaryWrapper
            accountId={accountId}
            isLoading={loading}
            let2OwnSummary={let2OwnSummary}
            currentDate={currentDate}
            mode={MODE.RENEWAL}
            skipPayment={skipPayment}
            setLoading={setLoading}
            breaksNotSavedAlertEnabled={breaksNotSavedAlertEnabled}
          />
          {maxBreakList()}
        </>
      );
    }

    return (
      <>
        <Let2OwnRenewalCompletedBanner
          currentDate={currentDate}
          loading={loading}
          let2OwnSummary={let2OwnSummary}
          mobileview={mobileView}
        />
        {maxBreakList()}
      </>
    );
  }

  return <></>;
};
