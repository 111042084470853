/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from 'react';
import DateInput, {
  DateValue,
} from '@havenengineering/module-shared-library/dist/form/components/DateInput';
import { L2oSummary } from '@havenengineering/module-shared-owners-library/dist/interfaces/l2oSummary';
import { EventData } from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar';
import { isSameDate } from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar/utils';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';
import { DateTime } from 'luxon';

import {
  getArrivalBookingErrorByStatus,
  getDateArrivalFromSelection,
  getFullAreaCode,
} from '../../helpers/arrivals';
import {
  getPrivCardHolderSubmitValue,
  hasPlayPassTrial,
} from '../../helpers/calendar';
import {
  areDateRangesOverlapping,
  getDateTimeFromIso,
  getDurationInDays,
} from '../../helpers/dateTime';
import {
  fetchWrapper,
  withApiBaseUrl,
  withArrivalsApiBaseUrl,
  withEagleEyeApiBaseUrl,
  withPlayPassApiBaseUrl,
} from '../../helpers/fetch';
import {
  handleAddArrivalInfoOnCalendar,
  handleArrivalBookingOnCalendarGTM,
  handleSelectVisitTypeOnCalendar,
} from '../../helpers/googleTag';
import {
  ArrivalDataEnum,
  BreakEventStatus,
  EventDateSelection,
  Panels,
} from '../../pages/bookings';
import {
  evaluateHavenLetsInSelection,
  wouldArrivalsBeAboveTheMaximumConsecutiveDays,
} from '../helpers/bookings';
import styles from './Booking.module.scss';
import { ArrivalDetailsStep } from './BookingSteps/ArrivalDetailsStep';
import { MaintenanceDetailsStep } from './BookingSteps/MaintenanceDetailsStep';
import { PersonDetailsStep } from './BookingSteps/PersonDetailsStep';
import { TypeOfVisitStep } from './BookingSteps/TypeOfVisitStep';
import { BreakTransitions } from './BreakTransitions';
import { InfoSelection } from './InfoPanel';
import { PlayPassNoteDialog } from './PlayPassNoteDialog';

type BookingProps = {
  selection: InfoSelection;
  handleArrivalBooking: (shortCode: string) => void;
  handlePromoteLetting: () => void;
  handleDateSelectionChange: (newSelection: EventDateSelection) => void;
  handleSetInfo: (msg: string) => void;
  handleSetError: (msg: string) => void;
  handleSetAdvancedInfo: (msg: { title: string; subtitle: string }) => void;
  selectionBreakData: BreakDataResponse[];
  onLetWithHaven: () => void;
  onLetWithHavenSuccess: (outOfSeason: boolean) => void;
  letWithHavenEnabled: boolean;
  isParkClosed: boolean;
  allowedToLet: boolean;
  parkAllowsLetting: boolean;
  hasSubmittedApplication: boolean;
  firstEventStatus: BreakEventStatus;
  activeSeason: number;
  ownersCards: OwnersCardResponse | null;
  let2OwnSummary: L2oSummary | undefined;
  allPeakDatesData: PeakDatesData[];
};

enum BookingStep {
  TYPE_OF_VISIT,
  ARRIVAL_DETAILS,
  PERSONAL_DETAILS,
  MAINTENANCE_DETAILS,
}

export enum BookingType {
  OWNER,
  PRIVATE_LET,
  MAINTENANCE,
  PROMOTE_LETTING,
  LET_WITH_HAVEN,
}

export enum BreakDataCoreStatus {
  LET = 'Letting',
  WAITLIST = 'Waitlist',
  OWNER_USING = 'Owner using',
  UNDER_REPAIR = 'Under repair',
  UNAVAILABLE = 'Unavailable',
}

export interface ArrivalBookingForm {
  accountNumber: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  dateArrival: string[];
  timeArrival: string;
  isLetting: boolean;
  participants: Participant[];
  childNumber: number;
  toddlersNumber: number;
  licencePlateNumber: string[];
  letterEmail: string;
  pitchName: string;
  pitchNumber: string;
  privCardHolder: string;
  modeOfTransport: string;
  areaCode: string;
}

export type MaintenanceForm = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

// TODO: different booking types should be in separate components
export const Booking: FunctionComponent<BookingProps> = ({
  selection,
  handleArrivalBooking,
  handlePromoteLetting,
  handleDateSelectionChange,
  handleSetInfo,
  handleSetAdvancedInfo,
  handleSetError,
  selectionBreakData,
  onLetWithHaven,
  onLetWithHavenSuccess,
  letWithHavenEnabled,
  isParkClosed,
  allowedToLet,
  parkAllowsLetting,
  hasSubmittedApplication,
  firstEventStatus,
  activeSeason,
  ownersCards,
  let2OwnSummary,
  allPeakDatesData,
}) => {
  const { loggedInUser, activeAccount } = useAuthContext();

  const [activeStep, setActiveStep] = useState(BookingStep.TYPE_OF_VISIT);

  const [arrivalBookingForm, setArrivalBookingForm] =
    useState<ArrivalBookingForm>({
      accountNumber: activeAccount?.accountNo || '',
      email: loggedInUser?.webEmail || '',
      phoneNumber: '',
      firstName: '',
      lastName: '',
      dateArrival: getDateArrivalFromSelection(selection),
      timeArrival: '',
      isLetting: false,
      participants: [],
      childNumber: 0,
      toddlersNumber: 0,
      licencePlateNumber: ['', ''],
      letterEmail: '',
      pitchName: activeAccount?.zoneName || activeAccount?.areaName || '',
      pitchNumber: activeAccount?.pitchNumber || '',
      privCardHolder: '',
      modeOfTransport: '',
      areaCode: getFullAreaCode(activeAccount),
    });

  const maintenanceForm: MaintenanceForm = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
  };

  const [nextIsDisabled, setNextIsDisabled] = useState(false);
  const [bookingError, setBookingError] = useState('');
  const [playPassDialogIsOpen, setPlayPassDialogIsOpen] = useState(false);
  const [letWithHaven, setLetWithHaven] = useState(false);

  const promoteLetting =
    allowedToLet && !activeAccount?.acceptedForLet && !hasSubmittedApplication;

  const prevSeason = selection.dates.startDate.year < activeSeason;

  const defaultBookingType = prevSeason
    ? BookingType.OWNER
    : promoteLetting
    ? BookingType.PROMOTE_LETTING
    : letWithHavenEnabled && !isParkClosed && allowedToLet && parkAllowsLetting
    ? BookingType.LET_WITH_HAVEN
    : BookingType.OWNER;

  const [bookingType, setBookingType] =
    useState<BookingType>(defaultBookingType);

  const MAXIMUM_CONTINUOUS_BOOKING = 60;

  useEffect(() => {
    const areaCode = getFullAreaCode(activeAccount);
    setArrivalBookingForm((prev) => ({
      ...prev,
      ...{
        accountNumber: activeAccount?.accountNo || '',
        pitchName: activeAccount?.zoneName || activeAccount?.areaName || '',
        pitchNumber: activeAccount?.pitchNumber || '',
        areaCode: areaCode,
      },
    }));
  }, [activeAccount]);

  useEffect(() => {
    setArrivalBookingForm((prev) => ({
      ...prev,
      dateArrival: getDateArrivalFromSelection(selection),
    }));
  }, [selection]);

  const handleTypeOfVisitChange = async (type: BookingType) => {
    setBookingType(type);
    switch (type) {
      case BookingType.LET_WITH_HAVEN: {
        setLetWithHaven(letWithHavenEnabled);
        handleSelectVisitTypeOnCalendar(
          'let with haven',
          selection.dates.startDate.toFormat('yyyy-MM-dd'),
          firstEventStatus,
          selection.dates.endDate.toFormat('yyyy-MM-dd')
        );

        break;
      }
      case BookingType.PROMOTE_LETTING: {
        // gtm
        handleSelectVisitTypeOnCalendar(
          'sign up and earn',
          selection.dates.startDate.toFormat('yyyy-MM-dd'),
          firstEventStatus,
          selection.dates.endDate.toFormat('yyyy-MM-dd')
        );
        handlePromoteLetting();
        break;
      }
      default: {
        const { overlapWithHavenLet, withinSixWeeks, overlapWithPexHandover } =
          evaluateHavenLetsInSelection(selection, type);

        if (
          [
            BookingType.OWNER,
            BookingType.PRIVATE_LET,
            BookingType.MAINTENANCE,
          ].includes(type) &&
          overlapWithPexHandover
        ) {
          const validationMessage =
            'Your new holiday home may be sited during this selected period. Please contact your Owners team to confirm availability.';
          handleSetInfo(validationMessage);
          return;
        }

        if (!overlapWithHavenLet) {
          // gtm
          handleSelectVisitTypeOnCalendar(
            type === BookingType.OWNER
              ? 'owner visit'
              : type === BookingType.PRIVATE_LET
              ? 'private letting'
              : 'maintenance visit',
            selection.dates.startDate.toFormat('yyyy-MM-dd'),
            firstEventStatus,
            selection.dates.endDate.toFormat('yyyy-MM-dd')
          );

          setArrivalBookingForm((prev) => ({
            ...prev,
            isLetting: type === BookingType.PRIVATE_LET,
          }));

          const { startDate, endDate } = selection.dates;

          const arrivals = selection.events?.filter(
            ({ code }) => code === ArrivalDataEnum.ARRIVAL_BOOKING
          );

          if (
            [BookingType.PRIVATE_LET, BookingType.OWNER].includes(type) &&
            getDurationInDays(startDate, endDate) > MAXIMUM_CONTINUOUS_BOOKING
          ) {
            const bookingTypeString =
              type === BookingType.PRIVATE_LET ? 'private let' : 'owner visit';
            handleSetInfo(
              `A single ${bookingTypeString} cannot exceed ${MAXIMUM_CONTINUOUS_BOOKING} days`
            );
            break;
          } else if (
            wouldArrivalsBeAboveTheMaximumConsecutiveDays(
              startDate,
              endDate,
              arrivals as EventData<ArrivalBooking>[],
              MAXIMUM_CONTINUOUS_BOOKING
            )
          ) {
            handleSetInfo(
              `Consecutive private lets and owner visits cannot exceed ${MAXIMUM_CONTINUOUS_BOOKING} days`
            );
            break;
          }

          if (
            type === BookingType.PRIVATE_LET &&
            ownersCards?.accountIsCharity !== true &&
            hasPlayPassTrial(activeAccount?.parkCode)
          ) {
            await validateHavenBreakPattern(arrivalBookingForm.dateArrival);
          } else {
            setActiveStep(BookingStep.ARRIVAL_DETAILS);
          }
        } else {
          const validationMessage = withinSixWeeks
            ? 'The dates selected overlap with breaks already on let with Haven, and booking amendments have now passed.'
            : 'The dates selected overlap with breaks already on let with Haven.';
          handleSetInfo(validationMessage);
        }
      }
    }
  };

  const validateHavenBreakPattern = async (dateArrival: string[]) => {
    const validationQuery = new URLSearchParams();
    validationQuery.append('from', dateArrival[0]);
    validationQuery.append('to', dateArrival[1]);
    try {
      const validBreake = await fetchWrapper(
        withPlayPassApiBaseUrl(`/break/validate?${validationQuery.toString()}`),
        {
          method: 'GET',
        }
      );
      if (validBreake) {
        setActiveStep(BookingStep.ARRIVAL_DETAILS);
      } else {
        setPlayPassDialogIsOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePlayPassNoteAccept = () => {
    setActiveStep(BookingStep.ARRIVAL_DETAILS);
    setPlayPassDialogIsOpen(false);
  };

  const handleArrivalDetailsChange = (data: any) => {
    // gtm
    handleAddArrivalInfoOnCalendar(
      arrivalBookingForm.dateArrival?.[0],
      arrivalBookingForm.dateArrival?.[1],
      getDateTimeFromIso(arrivalBookingForm.dateArrival?.[1]).diff(
        getDateTimeFromIso(arrivalBookingForm.dateArrival?.[0]),
        'days'
      ).days,
      bookingType === BookingType.OWNER
        ? 'owner visit'
        : bookingType === BookingType.PRIVATE_LET
        ? 'private letting'
        : 'maintenance visit',
      data.modeOfTransport,
      data.timeArrival
    );

    setArrivalBookingForm((prev) => ({
      ...prev,
      ...data,
    }));
    setActiveStep(
      bookingType === BookingType.MAINTENANCE
        ? BookingStep.MAINTENANCE_DETAILS
        : BookingStep.PERSONAL_DETAILS
    );
  };

  const handlePersonDetailsChange = (data: any) => {
    setNextIsDisabled(true);
    const arrivalBooking = {
      ...arrivalBookingForm,
      ...data,
    };
    setArrivalBookingForm(arrivalBooking);
    createArrivalBooking(arrivalBooking);
  };

  interface CreateRegistrationBody {
    accountNumber: string;
    firstName: string;
    lastName: string;
    ownerEmail: string;
    phoneNumber: string;
    bookingInterval: string[];
    timeArrival: string;
    isGuestBooking: boolean;
    licencePlateNumber: string;
    modeOfTransport: string;
    leadGuestEmail: string;
    areaName: string;
    areaCode: string;
    pitchNumber: string;
    ownerCardHolder: string;
    toddlersNumber?: number;
    childNumber?: number;
    additionalGuests?: { name: string; phoneNumber: string }[];
    isCharity: boolean;
  }
  const createArrivalBooking = async (arrivalBooking: any) => {
    setBookingError('');

    const {
      accountNumber,
      firstName,
      lastName,
      phoneNumber,
      dateArrival: bookingInterval,
      timeArrival,
      isLetting: isGuestBooking,
      participants: additionalGuests,
      childNumber,
      modeOfTransport,
      toddlersNumber,
      pitchName: areaName,
      areaCode,
      pitchNumber,
    } = arrivalBooking;

    const body: CreateRegistrationBody = {
      accountNumber,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      ownerEmail: arrivalBooking.email?.trim(),
      phoneNumber,
      bookingInterval,
      timeArrival,
      isGuestBooking,
      leadGuestEmail: arrivalBooking.letterEmail?.trim(),
      licencePlateNumber: arrivalBooking.licencePlateNumber
        .filter(Boolean)
        .join(','),
      modeOfTransport,
      areaName,
      areaCode,
      pitchNumber,
      ownerCardHolder: getPrivCardHolderSubmitValue(
        arrivalBooking.privCardHolder
      ),
      isCharity: !!activeAccount?.isCharity,
    };

    if (body.isGuestBooking) {
      body.additionalGuests = additionalGuests;
      body.toddlersNumber = toddlersNumber;
      body.childNumber = childNumber;
    }

    handleArrivalBookingOnCalendarGTM(arrivalBooking, 'arrival request');

    try {
      if (
        ownersCards?.accountIsCharity &&
        body.isGuestBooking &&
        ownersCards.charities.length > 0
      ) {
        const ownerCard = await fetchWrapper(
          withEagleEyeApiBaseUrl(`/owner-card/charity-arrival`),
          {
            method: 'POST',
            credentials: 'include',
            headers: { 'x-account-number': activeAccount?.accountNo } as Record<
              string,
              string
            >,
            body: JSON.stringify({
              expirationDate: body.bookingInterval[1],
              firstName: body.firstName,
              lastName: body.lastName,
              ownerAccountNumber: activeAccount?.accountNo,
              parkCode: activeAccount?.parkCode,
              pitchNumber: activeAccount?.pitchNumber,
              areaCode: activeAccount?.areaCode,
              zoneCode: activeAccount?.zoneCode,
            }),
          }
        );
        body.ownerCardHolder = ownerCard.cardNumber;
      }

      const response = await fetchWrapper(withArrivalsApiBaseUrl('/booking'), {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(body),
      });
      if (response.shortCode) {
        handleArrivalBooking(response.shortCode);

        handleArrivalBookingOnCalendarGTM(
          arrivalBooking,
          'arrival confirmation'
        );
      } else {
        setBookingError(getArrivalBookingErrorByStatus(500));
        setNextIsDisabled(false);
      }
    } catch (error) {
      const errorMessage = getArrivalBookingErrorByStatus(error?.status);
      setBookingError(errorMessage);
      setNextIsDisabled(false);
    }

    try {
      await fetchWrapper(withApiBaseUrl('/letting/update-status'), {
        method: 'PUT',
        body: JSON.stringify({
          accountNumber: body.accountNumber,
          isLetting: body.isGuestBooking,
        }),
      });
    } catch (error) {
      // The result of this call is not affect the registartion flow
      console.error(error);
    }
  };

  const handleNewDateSelection = (
    value: DateValue,
    prop: 'startDate' | 'endDate' | 'singleDate'
  ) => {
    const newDate = DateTime.fromJSDate(value as Date).startOf('day');
    const newSelection =
      prop === 'singleDate'
        ? { startDate: newDate, endDate: newDate }
        : { ...selection.dates, [prop]: newDate };
    const arrivalBooking = {
      ...arrivalBookingForm,
      dateArrival: getDateArrivalFromSelection({ dates: newSelection }),
    };
    setArrivalBookingForm(arrivalBooking);
    handleDateSelectionChange(newSelection);
  };

  useEffect(() => {
    const arrivalBookingsToCancel: ArrivalBooking[] =
      selection?.events
        ?.filter(
          (event) =>
            event.code === ArrivalDataEnum.ARRIVAL_BOOKING &&
            event.days.length > 1 &&
            !event.data.isCancelled
        )
        ?.filter((event) => {
          const arrivalsStartDate = DateTime.fromISO(
            event.data.dateArrival
          ).startOf('day');
          const arrivalsEndDate = DateTime.fromISO(
            event.data.dueToLeaveDate
          ).startOf('day');

          return selectionBreakData?.some((breakData) =>
            areDateRangesOverlapping(
              {
                startDate: DateTime.fromISO(breakData.startDate).startOf('day'),
                endDate: DateTime.fromISO(breakData.endDate).startOf('day'),
              },
              {
                startDate: arrivalsStartDate,
                endDate: arrivalsEndDate,
              }
            )
          );
        })
        ?.map((event) => event.data) || [];

    if (letWithHaven && arrivalBookingsToCancel.length > 0) {
      handleSetInfo(
        `${arrivalBookingsToCancel.length} overlapping owner/guest booking${
          arrivalBookingsToCancel.length > 1 ? 's' : ''
        } will be removed!`
      );
    }
  }, [selection, letWithHaven]);

  useEffect(() => {
    const overLappingLettingBreaks = selection.events?.filter(
      (event) => event.data?.packageId
    );

    const overlappingBreakCount = overLappingLettingBreaks?.length;

    if (overlappingBreakCount && activeStep === BookingStep.PERSONAL_DETAILS) {
      handleSetInfo('Your existing booking(s) may be replaced');
    }
  }, [selection, letWithHaven, activeStep]);

  const getActiveStep = (currentStep: BookingStep) => {
    switch (currentStep) {
      case BookingStep.TYPE_OF_VISIT:
        return (
          <>
            <div className={styles.datePickerContainer}>
              <DateInput
                className="fs-mask"
                id="dateArrival"
                label="Arrival date / Start date"
                value={selection.dates.startDate.toJSDate()}
                onChange={(date) => handleNewDateSelection(date, 'startDate')}
              />
              <DateInput
                className="fs-mask"
                id="dueToLeaveDate"
                label="Departure date / End date"
                value={selection.dates.endDate.toJSDate()}
                minDate={selection.dates.startDate.toJSDate()}
                onChange={(date) => handleNewDateSelection(date, 'endDate')}
                error={
                  selection.dates.endDate < selection.dates.startDate
                    ? 'Departure date cannot be before arrival date'
                    : ''
                }
              />
            </div>
            {activeAccount && (
              <TypeOfVisitStep
                bookingType={bookingType}
                handleNext={(type) => handleTypeOfVisitChange(type)}
                letWithHavenDisabled={isParkClosed}
                letWithHavenHidden={
                  !allowedToLet || !parkAllowsLetting || prevSeason
                }
                maintenanceDisabled={
                  !isSameDate(
                    selection.dates.startDate,
                    selection.dates.endDate
                  )
                }
                promoteLettingSignUp={promoteLetting}
                letWithHavenEnabled={letWithHavenEnabled}
                nextIsDisabled={
                  selection.dates.endDate < selection.dates.startDate
                }
                selectedDates={selection.dates}
              />
            )}
          </>
        );
      case BookingStep.ARRIVAL_DETAILS:
        return (
          <ArrivalDetailsStep
            form={{
              licencePlateNumber: arrivalBookingForm.licencePlateNumber,
              modeOfTransport: arrivalBookingForm.modeOfTransport,
              timeArrival: arrivalBookingForm.timeArrival,
            }}
            bookingType={bookingType}
            handleNext={(data) => handleArrivalDetailsChange(data)}
            handlePrev={() => setActiveStep(BookingStep.TYPE_OF_VISIT)}
          />
        );
      case BookingStep.PERSONAL_DETAILS:
        return (
          <PersonDetailsStep
            form={{
              phoneNumber: arrivalBookingForm.phoneNumber,
              childNumber: arrivalBookingForm.childNumber,
              toddlersNumber: arrivalBookingForm.toddlersNumber,
              participants: arrivalBookingForm.participants,
              firstName: arrivalBookingForm.firstName,
              lastName: arrivalBookingForm.lastName,
              letterEmail: arrivalBookingForm.letterEmail,
            }}
            bookingType={bookingType}
            handleNext={(data) => handlePersonDetailsChange(data)}
            nextIsDisabled={nextIsDisabled}
            handlePrev={() => {
              setBookingError('');
              setActiveStep(BookingStep.ARRIVAL_DETAILS);
            }}
            bookingError={bookingError}
            ownersCards={ownersCards}
            handleSetInfo={handleSetInfo}
            selectionDates={selection.dates}
          />
        );
      case BookingStep.MAINTENANCE_DETAILS:
        return (
          <MaintenanceDetailsStep
            form={maintenanceForm}
            handleNext={(data: any) => handlePersonDetailsChange(data)}
            nextIsDisabled={nextIsDisabled}
            handlePrev={() => {
              setBookingError('');
              setActiveStep(BookingStep.ARRIVAL_DETAILS);
            }}
            bookingError={bookingError}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {playPassDialogIsOpen && (
        <PlayPassNoteDialog
          handleOk={() => handlePlayPassNoteAccept()}
          handleCancel={() => setPlayPassDialogIsOpen(false)}
          startDate={arrivalBookingForm.dateArrival?.[0]}
          endDate={arrivalBookingForm.dateArrival?.[1]}
          duration={
            getDateTimeFromIso(arrivalBookingForm.dateArrival?.[1]).diff(
              getDateTimeFromIso(arrivalBookingForm.dateArrival?.[0]),
              'days'
            ).days
          }
        />
      )}
      <div className={styles.bookingContainer}>
        {letWithHaven ? (
          <BreakTransitions
            breaks={selectionBreakData}
            handleCancel={onLetWithHaven}
            handleLetWithHaven={onLetWithHavenSuccess}
            handleSetError={handleSetError}
            handleSetInfo={handleSetInfo}
            type={Panels.LET_WITH_HAVEN}
            let2OwnSummary={let2OwnSummary}
            handleSetAdvancedInfo={handleSetAdvancedInfo}
            allPeakDatesData={allPeakDatesData}
          />
        ) : (
          getActiveStep(activeStep)
        )}
      </div>
    </>
  );
};
