import { DataLayerArgs } from 'react-gtm-module';
import { DateTime } from 'luxon';

import { SwappableBreak } from '../components/helpers/bookings';
import { BookingTab, BreakEventStatus } from '../pages/bookings';
import {
  convertArrivalTimeTo24format,
  getDateTimeFromIso,
  now,
} from './dateTime';
import { getDrainDownOptionByTag } from './draindown';
import { getParkByCode } from './parks';

declare global {
  interface Window {
    dataLayer: any;
  }
}

const analytics = (
  analyticsData: DataLayerArgs['dataLayer'],
  shouldAutoClear = true
) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ ...analyticsData, _clear: shouldAutoClear });
};

const getParkDataByAccountNumber = async (
  accountNumber: string,
  unauthRedirect = true
) => {
  const parkCode = accountNumber.substring(0, 2);
  const park = await getParkByCode(parkCode, unauthRedirect);

  return {
    parkCode,
    parkName: park?.parkName.toLowerCase() || '',
    parkRegion: park?.parkRegion.toLowerCase() || '',
  };
};

export const pageView = (
  pageSubType: string,
  pageName: string,
  pageType = 'owners account',
  authInfo?: GtmAuthInfo,
  pageInformation?: Record<string, string | number>
) => {
  const analyticsData = {
    event: 'virtual page view',
    pageType,
    pageSubType,
    pageName,
    pageId: 'no id',
    ...authInfo,
    ...pageInformation,
  };
  analytics(analyticsData, false);
};

export const formErrorHandling = (
  formName: string,
  errors: Array<{
    getErrorMessage: () => string;
    props: {
      label: string;
      name: string;
    };
  }>
) => {
  const errorFields = [
    ...new Set(
      errors.map(({ props }) => (props.label || props.name).toLowerCase())
    ),
  ];
  const sortedErrors = [...errorFields].sort();

  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'form error',
    formName,
    numErrorFields: errorFields.length,
    errorFields: sortedErrors.join('|'),
    firstErrorField: errorFields[0],
    firstErrorMessage: errors?.[0].getErrorMessage().toLowerCase() || '',
  };
  analytics(analyticsData, false);
};

export const accordionClick = (accordionName: string, open: boolean) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'accordion click',
    accordionName: accordionName.toLowerCase(),
    accordionAction: open ? 'open' : 'close',
  };
  analytics(analyticsData, false);
};

export const buttonClickGTMEvent = (details?: Record<string, any>) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'button click',
    ...(details || {}),
  };
  analytics(analyticsData, false);
};

export const scrollEvent = (pageSection: string) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'page scroll',
    pageSection,
  };
  analytics(analyticsData, false);
};

export const insuranceConfirmation = async (
  insuranceData: InsuranceData,
  status: FormSubmissionStatus
) => {
  const parkData = await getParkDataByAccountNumber(insuranceData.ownerAccount);

  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'insurance certificate confirmation',
    ...status,
    ownerAccount: insuranceData.ownerAccount,
    ...parkData,
    insuranceProvider: insuranceData.insuranceProvider,
    insuranceExpiry: insuranceData.insuranceExpiry,
    zendeskTicketId: insuranceData.ticketId,
  };
  analytics(analyticsData, false);
};

export const directDebitConfirmation = async (
  debitData: DebitData,
  status: FormSubmissionStatus
) => {
  const parkData = await getParkDataByAccountNumber(debitData.ownerAccount);

  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'direct debit confirmation',
    ...status,
    ownerAccount: debitData.ownerAccount,
    ...parkData,
    zendeskTicketId: debitData.ticketId,
  };
  analytics(analyticsData, false);
};

export const refundConfirmation = async (
  refundData: RefundData,
  status: FormSubmissionStatus
) => {
  const {
    ownerAccount,
    ticketId,
    siteFeeBalance,
    refundAmount,
    refundType,
    requiresCallback,
  } = refundData;
  const parkData = await getParkDataByAccountNumber(ownerAccount);

  const analyticsData = {
    event: 'visitor interaction',
    interactionName: `${refundType.replace('-', ' ')} refund ${
      requiresCallback ? 'callback' : ''
    } confirmation`,
    ...status,
    ownerAccount,
    ...parkData,
    zendeskTicketId: ticketId,
    siteFeeBalance,
    refundAmount,
  };
  analytics(analyticsData, false);
};

export const accountEnquiry = async (
  enquiryData: EnquiryData,
  status: FormSubmissionStatus
) => {
  const parkData = await getParkDataByAccountNumber(enquiryData.ownerAccount);

  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'account enquiry confirmation',
    ...status,
    ...parkData,
    zendeskTicketId: enquiryData.ticketId,
    ownerAccount: enquiryData.ownerAccount,
    numFiles: enquiryData.numFiles,
    fileSize: enquiryData.fileSize || undefined,
  };
  analytics(analyticsData, false);
};

const getDateFromSlot = (slot?: DrainDownSlot) =>
  slot ? DateTime.fromISO(slot.from).toISODate() : 'self organised';

export const drainDownConfirmation = async (
  drainDownData: DrainDownForm & {
    drainDownSlot?: DrainDownSlot;
    reconnectionSlot?: DrainDownSlot;
    bookingId?: string;
  },
  status: FormSubmissionStatus
) => {
  const {
    accountNumber,
    pitchName,
    pitchNumber,
    option,
    drainDownSlot,
    reconnectionSlot,
    bookingId,
  } = drainDownData;

  const selectedService = getDrainDownOptionByTag(option);

  const revenue = selectedService?.price || 0;
  // TODO calc revenue based on new extra services

  const unauthRedirect = false;
  const parkData = await getParkDataByAccountNumber(
    accountNumber,
    unauthRedirect
  );

  analytics({ ecommerce: null }, false);
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'drain down confirmation',
    ...status,
    ownerAccount: accountNumber,
    ...parkData,
    pitchName: pitchName.toLowerCase(),
    pitchNumber,
    serviceType: 'drain down',
    serviceVariant: selectedService?.name.toLowerCase() || '',
    bookedWeek: getDateFromSlot(drainDownSlot),
    reconnectionWeek: getDateFromSlot(reconnectionSlot),
    ecommerce: {
      purchase: {
        actionField: {
          id: bookingId,
          revenue: revenue.toFixed(2),
        },
        products: [
          {
            brand: 'haven',
            category: 'owner services',
            name: 'drain down',
            variant: selectedService?.name.toLowerCase(),
            price: selectedService?.price.toFixed(2),
            quantity: 1,
          },
        ],
      },
    },
  };
  analytics(analyticsData, false);
};

export const newsArticleCtaClick = () => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'referral callback request',
    successFlag: 'success',
  };
  analytics(analyticsData, false);
};

export const filterClick = (filterClickData: FilterClickData) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'filter click',
    ...filterClickData,
  };
  analytics(analyticsData, false);
};

export const paymentConfirmation = async (
  paymentConfirmationData: PaymentConfirmationData
) => {
  const parkData = await getParkDataByAccountNumber(
    paymentConfirmationData.ownerAccount
  );

  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'owner payment confirmation',
    authStatus: 'logged in', // login required
    ...paymentConfirmationData,
    ...parkData,
    revenue: paymentConfirmationData.revenue.toFixed(2),
    siteFeeBalance: paymentConfirmationData.siteFeeBalance.toFixed(2),
  };
  analytics(analyticsData, false);
};

export const handleGTMArrivalManagement = async (
  arrivalData: any,
  arrivalManagementType: string
) => {
  const parkData = await getParkDataByAccountNumber(arrivalData.accountNo);

  const basicData = {
    event: 'visitor interaction',
    interactionName: `arrivals ${arrivalManagementType}`,
    successFlag: arrivalData.successFlag,
    errorType: arrivalData.errorType,
    shortCode: arrivalData.shortCode,
    ownerAccount: arrivalData.accountNo,
    ...parkData,
    registrationType: arrivalData.isLetting ? 'letting' : 'owner',
    pitchName: arrivalData.areaName,
    pitchNumber: arrivalData.pitchNumber,
    // no need to explicitly specify the timezone as UK time is received from the backend az UTC
    arrivalDate: DateTime.fromISO(arrivalData.dateArrival).toFormat(
      'yyyy-MM-dd'
    ),
    // date handling same as for arrivalDate
    arrivalSeason: DateTime.fromISO(arrivalData.dateArrival).toFormat('yyyy'),
    arrivalSeasonFlag: DateTime.fromISO(arrivalData.dateArrival)
      .diff(now().startOf('year'), ['years', 'months'])
      .toObject().years
      ? 'next year'
      : 'this year',
    // date handling same as for arrivalDate
    departureDate: DateTime.fromISO(arrivalData.dueToLeaveDate).toFormat(
      'yyyy-MM-dd'
    ),
    duration: DateTime.fromISO(arrivalData.dueToLeaveDate)
      .diff(DateTime.fromISO(arrivalData.dateArrival).startOf('day'), [
        'days',
        'hours',
      ])
      .toObject().days,
    adults: arrivalData.users.length + 1,
    children: arrivalData.childNumber,
    infants: arrivalData.toddlersNumber,
    numGuests:
      arrivalData.users.length +
      1 +
      arrivalData.childNumber +
      arrivalData.toddlersNumber,
    numUnderSixteens: arrivalData.childNumber + arrivalData.toddlersNumber,
    partyConfig: `${arrivalData.users.length + 1} adults|${
      arrivalData.childNumber
    } children|${arrivalData.toddlersNumber} infants`,
  };

  const analyticsData =
    'download car pass' === arrivalManagementType
      ? basicData
      : {
          ...basicData,
          daysToArrival: DateTime.fromISO(arrivalData.dateArrival)
            .diff(now().startOf('day'), ['days', 'hours'])
            .toObject().days,
          arrivalTime: convertArrivalTimeTo24format(arrivalData.timeArrival),
        };

  analytics(analyticsData, false);
};

export const handleLettingAdvertGAView = () => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'overlay impression',
    overlayType: 'content',
    overlayName: 'simply letting is amazing',
  };
  analytics(analyticsData, false);
};

export const handleLettingAdvertSubmit = (url: string) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'overlay click',
    overlayType: 'content',
    overlayName: 'simply letting is amazing',
    overlayAction: url,
  };
  analytics(analyticsData, false);
};

export const handleLettingAdvertDismiss = () => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'overlay impression',
    overlayType: 'content',
    overlayName: 'simply letting is amazing',
    overlayAction: 'close',
  };
  analytics(analyticsData, false);
};

export const handleTooltipDisplayed = (
  tooltipType: string,
  featureName = 'booking calendar'
) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'overlay impression',
    overlayType: 'tool tip',
    overlayName: `${featureName}:${tooltipType}`,
  };
  analytics(analyticsData, false);
};

export const handleTooltipClick = (
  tooltipType: string,
  featureName = 'booking calendar',
  action = 'ok or dismiss'
) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'overlay click',
    overlayType: 'tool tip',
    overlayName: `${featureName}:${tooltipType}`,
    overlayAction: action,
  };
  analytics(analyticsData, false);
};

export const handleCalendarListViewChange = (tab: BookingTab) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'change view',
    currentView: `${tab ? 'calendar' : 'list'} view`,
    newView: `${tab ? 'list' : 'calendar'} view`,
  };
  analytics(analyticsData, false);
};

export const handleDateSelectOnCalendar = (
  startDate: string,
  breakStatus: BreakEventStatus,
  endDate: string
) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'booking calendar select date',
    startDate: startDate,
    breakStatus: breakStatus,
    endDate: endDate,
  };
  analytics(analyticsData, false);
};

export const handleSelectVisitTypeOnCalendar = (
  visitType:
    | 'owner visit'
    | 'private letting'
    | 'maintenance visit'
    | 'sign up and earn'
    | 'let with haven',
  startDate: string,
  breakStatus: BreakEventStatus,
  endDate: string
) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'booking calendar select visit type',
    visitType: visitType,
    startDate: startDate,
    breakStatus: breakStatus,
    endDate: endDate,
  };
  analytics(analyticsData, false);
};

export const handleLettingDetailsOnCalendarGTM = (
  breakStatus: string,
  startDate: string,
  endDate: string,
  duration: string
) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'booking calendar let with haven details',
    visitType: 'let with haven',
    startDate,
    breakStatus,
    endDate,
    duration,
  };
  analytics(analyticsData, false);
};

export const handleLettingConfirmationOnCalendarGTM = (
  breakStatus: string,
  startDate: string,
  endDate: string,
  duration: string
) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'booking calendar let with haven confirmation',
    visitType: 'let with haven',
    startDate,
    breakStatus,
    endDate,
    duration,
  };
  analytics(analyticsData, false);
};

export const handleAddArrivalInfoOnCalendar = (
  startDate: string,
  endDate: string,
  duration: number,
  visitType:
    | 'owner visit'
    | 'private letting'
    | 'maintenance visit'
    | 'sign up and earn',
  arrivalMethod: 'car' | 'bus' | 'train' | 'walking' | 'taxi',
  arrivalTime: string
) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'booking calendar add arrival',
    startDate: startDate,
    endDate: endDate,
    duration: `${duration}`,
    visitType: visitType,
    arrivalMethod: arrivalMethod,
    arrivalTime: arrivalTime,
  };
  analytics(analyticsData, false);
};

export const handleArrivalBookingOnCalendarGTM = (
  booking: ArrivalBooking,
  saveBookingType:
    | 'add guest info'
    | 'arrival confirmation'
    | 'cancel arrival'
    | 'edit arrival'
    | 'arrival request'
) => {
  const start =
    typeof booking.dateArrival === 'string'
      ? booking.dateArrival
      : booking.dateArrival?.[0];

  const end =
    typeof booking.dateArrival === 'string'
      ? booking.dueToLeaveDate
      : booking.dateArrival?.[1];

  const duration = Math.ceil(
    getDateTimeFromIso(end).diff(getDateTimeFromIso(start), 'days').days
  );
  const adults = booking.users?.length + 1 || 0;
  const children = booking.childNumber;
  const infants = booking.toddlersNumber;

  const analyticsData = {
    event: 'visitor interaction',
    interactionName: `booking calendar ${saveBookingType}`,
    startDate: start,
    endDate: end,
    duration: `${duration}`,
    visitType: booking.isLetting ? 'private letting' : 'owner visit',
    arrivalMethod: booking.modeOfTransport,
    arrivalTime: booking.timeArrival,
    adults: `${adults}`,
    children: `${children}`,
    infants: `${infants}`,
    numGuests: `${adults + children + infants}`,
    numUnderSixteens: `${children + infants}`,
    partyConfig: `${adults} adults | ${children} children | ${infants} infants`,
    arrivalSeason: `${typeof start === 'string' && start.split('-')?.[0]}`,
  };
  analytics(analyticsData, false);
};

export const handleEditBookingOnCalendarGTM = (booking: SwappableBreak) => {
  const startDate = booking.startDate;
  const endDate = booking.endDate;

  const breakType =
    `${booking.breakPeakType} (${booking.breakDaysType})`.toLowerCase();

  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'booking calendar edit date',
    breakType,
    breakStatus: 'haven',
    startDate,
    endDate,
  };
  analytics(analyticsData, false);
};

export const handleContactDetailsUpdate = ({
  detailType,
  successFlag,
  errorType,
}: UpdatedContactData) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'update contact details',
    detailType,
    successFlag,
    errorType,
  };
  analytics(analyticsData, false);
};

export const handlePreferencesUpdate = ({
  successFlag,
  errorType,
}: FormSubmissionStatus) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'update contact preferences',
    successFlag,
    errorType,
  };
  analytics(analyticsData, false);
};

export const handleNotificationsUpdate = ({
  successFlag,
  errorType,
}: FormSubmissionStatus) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'update notifications',
    successFlag,
    errorType,
  };
  analytics(analyticsData, false);
};

export const handlePasswordChange = ({
  successFlag,
  errorType,
}: FormSubmissionStatus) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'change password',
    successFlag,
    errorType,
  };
  analytics(analyticsData, false);
};

export const handleGTMGiveMeACall = ({
  sectionTitle,
  successFlag,
  errorType,
}: GiveMeACallData) => {
  analytics({ ecommerce: null });
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'request call back',
    product: 'part exchange',
    sectionTitle,
    successFlag,
    errorType,
  };
  analytics(analyticsData, false);
};

export const handleGTMLettingCalcWidgetInteraction = ({
  fieldName,
}: InteractionCalcWidgetGTMData) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'letting widget interaction',
    fieldName,
  };
  analytics(analyticsData, false);
};

export const handleGTMBannerImpression = (
  interactionName: string,
  {
    button_cta,
    image_url,
    link_url,
    page_position,
    title,
    variant,
  }: BannerImpressionGTMData
) => {
  analytics({}, false);
  const analyticsData = {
    event: 'visitor interaction',
    interactionName,
    banner: [
      {
        category: 'owners',
        type: 'lettings',
        variant: variant.toLowerCase(),
        image_url,
        link_url: `${window?.location?.origin || ''}${link_url}`,
        title: title.toLowerCase(),
        button_cta: button_cta.toLowerCase(),
        page_position: page_position.toLowerCase(),
      },
    ],
  };
  analytics(analyticsData);
};

export const viewPlayPassWarningGTMEvent = (
  startDate?: string,
  endDate?: string,
  duration?: number
) => {
  const analyticsData = {
    event: 'visitor interaction',
    interactionName: 'view play pass warning',
    startDate,
    endDate,
    duration,
  };
  analytics(analyticsData, false);
};
