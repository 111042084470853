import React, { ReactNode, useState } from 'react';
import { EventData } from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar';

import { BookingContext } from './bookingContext';

interface BookingProviderProps {
  children: ReactNode;
  initialBooking: ArrivalBooking;
  arrivals?: EventData<ArrivalBooking>[];
}

export const BookingProvider = ({
  children,
  initialBooking,
  arrivals,
}: BookingProviderProps) => {
  const [booking, setBooking] = useState<ArrivalBooking>(initialBooking);

  const handleTransportUpdate = (body: UpdateBookingTransportBody) => {
    setBooking((prev) => ({
      ...prev,
      ...body,
    }));
  };

  const handleDateUpdate = (body: UpdateBookingDateBody) => {
    setBooking((prev) => ({
      ...prev,
      ...body,
    }));
  };

  const handleUpsertParticipants = (body: UpsertParticipantsBody) => {
    setBooking((prev) => ({
      ...prev,
      childNumber: body.childNumber,
      toddlersNumber: body.toddlersNumber,
      users: body.additionalGuests || [],
    }));
  };

  const handleCancel = () => {
    setBooking((prev) => ({
      ...prev,
      isCancelled: true,
    }));
  };

  const value = {
    booking,
    arrivals,
    handleTransportUpdate,
    handleDateUpdate,
    handleUpsertParticipants,
    handleCancel,
  };

  return (
    <BookingContext.Provider value={value}>{children}</BookingContext.Provider>
  );
};
