export interface LettingApplicationFields {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  accountNumber: string;
  isPetFriendly: boolean;
  isPrivatelyLet: boolean;
  isTermsAndConditionsAccepted: boolean;
  hasSafetyTest: boolean;
  hasRepairCoverPlan: boolean;
  hasMattressProtector: boolean;
  isAcceptedToLet: boolean;
  type: ApplicationType;
  status: ApplicationStatus;
}

export enum LettingType {
  Flexi = 'Flexi',
  Fixed = 'Fixed',
  Let2Offset = 'Let2Offset',
}

export interface LettingApplicationResponse {
  count: number;
  rows: LettingApplicationFields[];
}

export enum BreakVisitType {
  LET_WITH_HAVEN,
  WAITLIST,
  OWNER_OR_PRIVATE,
  MAINTENANCE,
  NONE,
}

export enum ApplicationType {
  flexi = 'FLEXI',
  fixed = 'FIXED',
}

export enum ApplicationStatus {
  draft = 'DRAFT',
  submitted = 'SUBMITTED',
}

export interface LettingAdvertisement {
  displayAd: boolean;
  lastYearOfLet?: number;
}

export interface LettingAdvertForBanner {
  id: string;
  title: string;
  heading: string;
  text: string;
  imageUrl?: string;
  ctaText: string;
  contactDetails?: ContactDetails;
}

interface ContactDetails {
  name: string;
  jobTitle: string;
  phone: string;
  email: string;
}

export interface LettingSummary {
  incomeStillToEarn: number;
  lettingGrade: number;
  frozenSubletIncome: number;
  purchaseDebt: number;
  incomeTransferred: number;
  totalBreaksIncome: number;
  endOfSeasonProjectedIncome: number;
  fixedOrFlexi: string;
  typeOfLetting: string;
  petFriendly: boolean;
  acceptedForLetting: boolean;
  let2Own: boolean;
  isRenewing?: boolean;
  renewalCompleted?: boolean;
  partialPaymentMade?: boolean;
}

export interface LettingAccount {
  readyForRenewal: boolean;
  isRenewing: boolean;
}
