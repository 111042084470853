import React, { FunctionComponent } from 'react';
import { Button } from '@havenengineering/module-shared-owners-ui/dist/components/Button';

import { getIconBaseUrl } from '../NoticeMessage/NoticeMessage';
import { FailedBreakUpdates } from './BreakTransitions';
import styles from './LettingPartialSuccess.module.scss';

type Props = {
  handleOk: () => void;
  activeLettingAd?: Advert;
  failedBreakUpdates: FailedBreakUpdates[] | null;
};

export const LettingPartialSuccess: FunctionComponent<Props> = ({
  handleOk,
  failedBreakUpdates,
}) => (
  <div className={styles.partialSuccess}>
    <div className={styles.successIcon}>
      <img src={getIconBaseUrl('warning-circle.svg')} alt="" />
    </div>
    <div className={styles.successTitle}>Take another look</div>
    <div className={styles.successEmail}>
      Oops, the following breaks haven’t saved.
    </div>
    <div className={styles.successUpdated}>
      <ul>
        {failedBreakUpdates?.map((item) => (
          <li key={item.parkLettingPackageId}>
            {item.startDate} ({item.duration} nights)
          </li>
        ))}
      </ul>
      <p>Please try again or contact your Letting Admin.</p>
    </div>
    <Button onClick={handleOk}>Ok, got it!</Button>
  </div>
);
