import React, { FunctionComponent, useEffect, useState } from 'react';
import { LoadingIndicator } from '@havenengineering/module-shared-library/dist/components/LoadingIndicator';
import { L2oSummary } from '@havenengineering/module-shared-owners-library/dist/interfaces/l2oSummary';
import { ConfirmationOverlay } from '@havenengineering/module-shared-owners-ui/dist/components/ConfirmationOverlay';
import {
  DateSelection,
  DayEvent,
  EventData,
} from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar';
import { isToday } from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar/utils';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';
import FileSaver from 'file-saver';
import { DateTime } from 'luxon';

import { getBreakVisitType, isOffer } from '../../helpers/calendar';
import { now } from '../../helpers/dateTime';
import { ParkClosedDataTypeEnum, StyleTypes } from '../../pages/bookings';
import { BreakVisitType } from '../../types/lettings';
import { InlineMessage } from '../EventCalendarApendix/InlineMessage';
import {
  deleteArrivalBookingOrHavenLet,
  downloadCarpass,
  isBreakRemovalDisabled,
  sortBookingDataByArrivalDate,
} from '../helpers/bookings';
import styles from './BookingList.module.scss';
import BookingNavigationHeader from './BookingNavigationHeader';
import { EventListItem } from './EventListItem';
import { EventListPartExItem } from './EventListPartExItem';

interface BookingListProps {
  eventData: EventData[];
  onArrivalEdit: (
    selection: DateSelection,
    eventIds: Set<string> | null,
    data: ArrivalBooking
  ) => void;
  onLetWithHavenEdit: (
    selection: DateSelection,
    eventIds: Set<string> | null,
    data: BreakDataResponse
  ) => void;
  onKeyClick: () => void;
  onAddEventClick: () => void;
  refetchEventData: () => void;
  mobileView: boolean;
  allPeakDatesData: PeakDatesData[];
  let2OwnSummary?: L2oSummary;
}

const BookingList: FunctionComponent<BookingListProps> = ({
  eventData,
  onArrivalEdit,
  onLetWithHavenEdit,
  onKeyClick,
  onAddEventClick,
  refetchEventData,
  mobileView,
  allPeakDatesData,
  let2OwnSummary,
}) => {
  const { activeAccount } = useAuthContext();
  const [today] = useState<DateTime>(now().startOf('day'));
  const [selectedEvent, setSelectedEvent] = useState<EventData>();
  const [error, setError] = useState<string>();
  const [info, setInfo] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);
  const [todayEvents, setTodayEvents] = useState<EventData[]>([]);
  const [upcomingEvents, setUpcomingEvents] = useState<EventData[]>([]);

  useEffect(() => {
    const todayData: EventData[] = [];
    const upcomingData: EventData[] = [];
    eventData.forEach((event) => {
      if (
        event.code === ParkClosedDataTypeEnum.PARK_CLOSED ||
        isOffer(event.code) ||
        event.styleType === StyleTypes.DAY_STYLES_ONY
      ) {
        return;
      }

      let startDay = event.days.find(
        (day) => day.eventPosition === 'start'
      ) as DayEvent;
      let endDay = event.days.find(
        (day) => day.eventPosition === 'end'
      ) as DayEvent;
      const singleDay = event.days.find(
        (day) => day.eventPosition === 'single'
      ) as DayEvent;
      if (singleDay) {
        startDay = singleDay;
        endDay = singleDay;
      }

      if (!startDay || !endDay) {
        return;
      }

      if (today > endDay.date) {
        return; // pastData not used atm
      }
      if (isToday(startDay.date)) {
        todayData.push(event);
      } else {
        upcomingData.push(event);
      }
    });

    setTodayEvents(todayData);
    setUpcomingEvents(sortBookingDataByArrivalDate(upcomingData));
  }, [eventData, today]);

  const handleEventEdit = (eventSelection: EventData) => {
    let startDay = eventSelection.days.find(
      (day) => day.eventPosition === 'start'
    ) as DayEvent;
    let endDay = eventSelection.days.find(
      (day) => day.eventPosition === 'end'
    ) as DayEvent;
    const singleDay = eventSelection.days.find(
      (day) => day.eventPosition === 'single'
    ) as DayEvent;

    if (singleDay) {
      startDay = singleDay;
      endDay = singleDay;
    }

    if (!startDay || !endDay) {
      return;
    }

    const eventIdSet = new Set<string>();
    eventIdSet.add(eventSelection.id);

    const breakVisitType = getBreakVisitType(eventSelection.code);

    if (breakVisitType === BreakVisitType.LET_WITH_HAVEN) {
      onLetWithHavenEdit(
        {
          startDate: startDay.date,
          endDate: endDay.date,
        },
        eventIdSet,
        eventSelection.data
      );
    } else if (
      [BreakVisitType.OWNER_OR_PRIVATE, BreakVisitType.MAINTENANCE].includes(
        breakVisitType
      )
    ) {
      onArrivalEdit(
        {
          startDate: startDay.date,
          endDate: endDay.date,
        },
        eventIdSet,
        eventSelection.data
      );
    }
  };

  const getCarpassPDF = async (eventSelection?: EventData) => {
    setLoading(true);
    try {
      const booking = eventSelection?.data as ArrivalBooking;
      const response = await downloadCarpass(booking, activeAccount);
      const isAndroid = /(android)/i.test(window.navigator.userAgent);
      FileSaver.saveAs(response, `carpass-${booking.shortCode}.pdf`);

      if (isAndroid) {
        const url = window.URL.createObjectURL(response);

        if (window.navigator.userAgent.match('SamsungBrowser')) {
          const reader = new FileReader();
          reader.onloadend = () => {
            window.open(reader.result as string | undefined);
          };
          reader.readAsDataURL(response);
        } else {
          window.open(url, '_blank', '');
        }
      }
    } catch (error) {
      setError('Something went wrong, please try again');
    } finally {
      setLoading(false);
    }
  };

  const handleEventCancel = async (eventSelection?: EventData) => {
    if (!eventSelection) {
      return;
    }

    setLoading(true);
    const { success, error, info } = await deleteArrivalBookingOrHavenLet(
      activeAccount?.accountID as number,
      eventSelection,
      allPeakDatesData,
      let2OwnSummary
    );
    if (success) {
      refetchEventData();
    } else {
      error && setError(error);
      info && setInfo(info);
    }
    setLoading(false);
  };

  return (
    <>
      <ConfirmationOverlay
        isOpen={cancelDialogOpen}
        title="You're about to delete a booking!"
        content={
          <>
            <p>This action can’t be undone.</p>
            <p>
              <b>Are you sure?</b>
            </p>
          </>
        }
        handleOk={() => {
          setCancelDialogOpen(false);
          handleEventCancel(selectedEvent);
        }}
        handleCancel={() => setCancelDialogOpen(false)}
        okLabel="Yes, proceed"
        cancelLabel="No, go back"
      />
      <div className={styles.listWrapper}>
        {error && (
          <InlineMessage
            type="error"
            message={error}
            onClick={() => setError('')}
          />
        )}
        {info && (
          <InlineMessage
            type="info"
            message={info}
            onClick={() => setInfo('')}
          />
        )}
        {loading && (
          <div className={styles.loading}>
            <LoadingIndicator loading />
          </div>
        )}
        <BookingNavigationHeader
          monthLabel={today.toFormat('MMM yyyy')}
          handleTodayClick={() => ({})}
          onAddEventClick={onAddEventClick}
          onKeyClick={onKeyClick}
          mobileView={mobileView}
        />
        <div className={styles.list}>
          <section>
            <p>Today ({today.toFormat('EEEE dd')})</p>
            {todayEvents.map((event) => {
              if (event.styleType === StyleTypes.PART_EX) {
                return (
                  <EventListPartExItem key={event.id} label={event.label!} />
                );
              } else {
                return (
                  <EventListItem
                    key={event.id}
                    event={event}
                    handleEdit={(event) => handleEventEdit(event)}
                    handleCancel={(event) => {
                      setCancelDialogOpen(true);
                      setSelectedEvent(event);
                    }}
                    handleGetCarPass={getCarpassPDF}
                    handleSetError={setError}
                    isRemovalDisabled={isBreakRemovalDisabled(
                      event,
                      allPeakDatesData,
                      let2OwnSummary
                    )}
                    breakVisitType={getBreakVisitType(event.code)}
                    allPeakDatesData={allPeakDatesData}
                  />
                );
              }
            })}
            {!todayEvents.length && <span>No events</span>}
          </section>
          <section>
            <p>Upcoming</p>
            {upcomingEvents.map((event) => {
              if (event.styleType === StyleTypes.PART_EX) {
                return (
                  <EventListPartExItem key={event.id} label={event.label!} />
                );
              } else
                return (
                  <EventListItem
                    key={event.id}
                    event={event}
                    handleEdit={(event) => handleEventEdit(event)}
                    handleCancel={(event) => {
                      setCancelDialogOpen(true);
                      setSelectedEvent(event);
                    }}
                    handleGetCarPass={getCarpassPDF}
                    handleSetError={setError}
                    isRemovalDisabled={isBreakRemovalDisabled(
                      event,
                      allPeakDatesData,
                      let2OwnSummary
                    )}
                    breakVisitType={getBreakVisitType(event.code)}
                    allPeakDatesData={allPeakDatesData}
                  />
                );
            })}
            {!upcomingEvents.length && <span>No events</span>}
          </section>
        </div>
      </div>
    </>
  );
};

export default BookingList;
