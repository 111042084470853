import '../styles/global.scss';

import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import Router from 'next/router';
import { datadogRum } from '@datadog/browser-rum';
import { MicroFrontendProvider } from '@havenengineering/module-shared-micro-frontend-consumer/react';
import { AuthProvider } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';
import { FlagProvider } from '@unleash/nextjs/client';
import NProgress from 'nprogress';

import QualtricsSurveyIntercepts from '../components/QualtricsSurveyIntercepts/QualtricsSurveyIntercepts';
import { LettingsContextProvider } from '../contexts/lettings';
import { useScrollToTopOnNavigate } from '../hooks/useScrollToTopOnNavigate';
import { MainLayout } from '../layouts/MainLayout';
import { MinimalLayout } from '../layouts/MinimalLayout';
import { AuthenticationWrapper } from '../wrappers/authentication';
import { SiteFeeHoldingWrapper } from '../wrappers/siteFeeHolding';

const {
  publicRuntimeConfig: {
    PUBLIC_GTM_ID,
    PUBLIC_GTM_AUTH,
    PUBLIC_GTM_PREVIEW,
    PUBLIC_ENV,
    PUBLIC_OWNERS_NAVIGATION_FRAGMENT_URL,
    PUBLIC_OWNERS_NAVIGATION_FRAGMENT_CLUSTER_URL,
    PUBLIC_UNLEASH_FRONTEND_API_URL,
    UNLEASH_FRONTEND_API_TOKEN,
    PUBLIC_UNLEASH_APP_NAME,
    PUBLIC_DD_VERSION,
    PUBLIC_DD_RUM_APPLICATION_ID,
    PUBLIC_DD_SERVICE,
    PUBLIC_DD_DD_ENV,
    PUBLIC_DD_RUM_CLIENT_TOKEN,
    PUBLIC_DD_RUM_SAMPLE_RATE,
    PUBLIC_DD_RUM_DEFAULT_PRIVACY_LEVEL,
    PUBLIC_DD_RUM_REPLAY_SAMPLE_RATE,
  },
} = getConfig();

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
NProgress.configure({ showSpinner: false });

const microFrontends = {
  navigation: {
    url: PUBLIC_OWNERS_NAVIGATION_FRAGMENT_URL || '',
    clusterUrl: PUBLIC_OWNERS_NAVIGATION_FRAGMENT_CLUSTER_URL || '',
  },
};

if (typeof global.window !== 'undefined' && PUBLIC_GTM_ID) {
  TagManager.initialize({
    gtmId: PUBLIC_GTM_ID,
    auth: PUBLIC_GTM_AUTH,
    preview: PUBLIC_GTM_PREVIEW,
  });
}

const dataDogConfigDefaults = {
  DEFAULT_RUM_SAMPLERATE: 1,
  DEFAULT_RUM_REPLAY_SAMPLERATE: 0,
  DEFAULT_DD_SITE: 'datadoghq.eu',
};

const dataDogConfig = {
  env: PUBLIC_DD_DD_ENV,
  applicationId: PUBLIC_DD_RUM_APPLICATION_ID,
  clientToken: PUBLIC_DD_RUM_CLIENT_TOKEN,
  defaultPrivacyLevel: PUBLIC_DD_RUM_DEFAULT_PRIVACY_LEVEL,
  version: PUBLIC_DD_VERSION,
  site: dataDogConfigDefaults.DEFAULT_DD_SITE,
  service: PUBLIC_DD_SERVICE,
  sessionSampleRate: PUBLIC_DD_RUM_SAMPLE_RATE
    ? dataDogConfigDefaults.DEFAULT_RUM_SAMPLERATE
    : parseInt(PUBLIC_DD_RUM_SAMPLE_RATE),
  sessionReplaySampleRate: PUBLIC_DD_RUM_REPLAY_SAMPLE_RATE
    ? dataDogConfigDefaults.DEFAULT_RUM_REPLAY_SAMPLERATE
    : parseInt(PUBLIC_DD_RUM_REPLAY_SAMPLE_RATE),
};

if (typeof global.window !== 'undefined' && dataDogConfig) {
  datadogRum.init(dataDogConfig);
}

export const OwnersApp = ({ Component, pageProps, router }: AppProps) => {
  useScrollToTopOnNavigate();

  const headTitle = `Haven Owners${
    pageProps.pageTitle ? ` - ${pageProps.pageTitle}` : ''
  }`;

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  if (!pageProps.pageTitle) {
    console.warn('Page title missing for the path ', router.pathname);
  }

  const index =
    PUBLIC_ENV !== 'production' || router.pathname !== '/'
      ? 'noindex'
      : 'index';

  const unleashConfig = {
    url: PUBLIC_UNLEASH_FRONTEND_API_URL,
    clientKey: UNLEASH_FRONTEND_API_TOKEN,
    appName: PUBLIC_UNLEASH_APP_NAME,
  };

  const renderApp = () => {
    if (router.pathname === '/maintenance') return <Component />;
    if (router.pathname === '/account/verify-email')
      return (
        <MinimalLayout>
          <Component {...pageProps} key={router.asPath} />
        </MinimalLayout>
      );
    return (
      <MainLayout>
        <Component {...pageProps} key={router.asPath} />
      </MainLayout>
    );
  };

  return (
    <FlagProvider config={unleashConfig}>
      <div>
        <Head>
          <title>{headTitle}</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
          <meta name="robots" content={`${index},nofollow`} />
          <meta name="googlebot" content={`${index},nofollow`} />
        </Head>
        <MicroFrontendProvider microFrontends={microFrontends}>
          <AuthProvider>
            <AuthenticationWrapper
              availableForGuests={pageProps.availableForGuests}
            >
              <LettingsContextProvider>
                <SiteFeeHoldingWrapper
                  showSiteFeeHolding={pageProps.isSiteFeeHolding}
                >
                  <>
                    <QualtricsSurveyIntercepts />
                    {renderApp()}
                  </>
                </SiteFeeHoldingWrapper>
              </LettingsContextProvider>
            </AuthenticationWrapper>
          </AuthProvider>
        </MicroFrontendProvider>
      </div>
    </FlagProvider>
  );
};

export default OwnersApp;
