import React, { useEffect } from 'react';
import { Button } from '@havenengineering/module-shared-owners-ui/dist/components/Button';
import clsx from 'clsx';

import { viewPlayPassWarningGTMEvent } from '../../helpers/googleTag';
import styles from './PlayPassNoteDialog.module.scss';

type Props = {
  handleOk: () => void;
  handleCancel: () => void;
  startDate?: string;
  endDate?: string;
  duration?: number;
};

export const PlayPassNoteDialog = ({
  handleOk,
  handleCancel,
  duration,
  endDate,
  startDate,
}: Props) => {
  useEffect(() => {
    viewPlayPassWarningGTMEvent(startDate, endDate, duration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.dialogContent}>
        <Button
          variant="text"
          className={styles.closeButton}
          onClick={() => handleCancel()}
        >
          <img
            src="https://www.haven.com/assets/icons/close.svg"
            width={16}
            height={16}
            alt="close"
          />
        </Button>

        <img
          src="https://www.haven.com/assets/icons/info-circle-blue.svg"
          alt="info"
          width={64}
          height={64}
        />
        <h1 className={styles.title}>A note on Play Passes</h1>
        <p className={styles.text}>
          In order for your Private Guest(s) to be able to purchase a Play Pass
          online in advance of their Holiday you will need to select a break
          with a duration of 4 nights from Monday to Friday, 3 nights from
          Friday to Monday, 7 nights from Monday, Friday and Saturday or 14
          nights from Monday, Friday and Saturday.
        </p>
        <p className={clsx(styles.text, styles.bold)}>
          Are you sure you want to proceed?
        </p>
        <div className={styles.buttonContainer}>
          <Button size="large" onClick={() => handleOk()}>
            Yes, continue
          </Button>
          <Button
            size="large"
            variant="outlined"
            onClick={() => handleCancel()}
          >
            No, go back
          </Button>
        </div>
      </div>
    </div>
  );
};
