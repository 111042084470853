import { DateTime } from 'luxon';

import { DrainDownOption } from '../components/DrainDown/DrainDown.enum';
import drainDownOptions from '../resources/drainDownOptions.json';

export const isParkValidForDrainDown = (parkCode: string) =>
  !['CF'].includes(parkCode);

export const getDrainDownOptionByTag = (tag: string) =>
  drainDownOptions.find((option) => option.tag === tag) || null;

export const displayPrice = (price: number) =>
  `£${Number.isInteger(price) ? price : price.toFixed(2)}`;

export const getAvailableForByOptionType = (option: DrainDownOption) => {
  switch (option) {
    case DrainDownOption.basic:
    case DrainDownOption.heating:
    case DrainDownOption.diyCaravan:
    case DrainDownOption.essentialCh:
    case DrainDownOption.essentialNonCh:
    case DrainDownOption.advancedCh:
    case DrainDownOption.advancedNonCh:
    case DrainDownOption.premiumCh:
    case DrainDownOption.premiumNonCh:
      return 'caravan';
    case DrainDownOption.diyLodge:
    case DrainDownOption.lodge:
    case DrainDownOption.essentialLodge:
    case DrainDownOption.advancedLodge:
    case DrainDownOption.premiumLodge:
      return 'lodge';
    default:
      throw new Error('not supperoted option');
  }
};

export const getBaseTypeFromPackage = (packageOption: string) => {
  switch (packageOption) {
    case DrainDownOption.essentialCh:
    case DrainDownOption.advancedCh:
    case DrainDownOption.premiumCh:
      return 'ch';
    case DrainDownOption.essentialNonCh:
    case DrainDownOption.advancedNonCh:
    case DrainDownOption.premiumNonCh:
      return 'non-ch';
    case DrainDownOption.essentialLodge:
    case DrainDownOption.advancedLodge:
    case DrainDownOption.premiumLodge:
      return 'lodge';
    default:
      return packageOption;
  }
};

export const isDrainDownPackage = (packageOption: string) => {
  switch (packageOption) {
    case DrainDownOption.essentialCh:
    case DrainDownOption.advancedCh:
    case DrainDownOption.premiumCh:
    case DrainDownOption.essentialNonCh:
    case DrainDownOption.advancedNonCh:
    case DrainDownOption.premiumNonCh:
    case DrainDownOption.essentialLodge:
    case DrainDownOption.advancedLodge:
    case DrainDownOption.premiumLodge:
      return true;
    default:
      return false;
  }
};

// To enable when Drain Down will be necessary
// export const isParkValidForFrostWarning = (parkCode: string) =>
//   ['CT', 'SE', 'HA', 'BE', 'LA', 'CG', 'MM', 'BD', 'RE', 'PV'].includes(
//     parkCode
//   );

export const fromatBookedDates = (date: string) =>
  `w/c ${DateTime.fromISO(date).toFormat('d LLL yyyy')}`;
