import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import { useAuthContext } from '@havenengineering/module-shared-owners-ui/dist/contexts/auth';
import clsx from 'clsx';
import { DateTime } from 'luxon';

import { LettingsContext } from '../../../contexts/lettings';
import { isWithinInterval } from '../../../helpers/dateTime';
import { EventDateSelection } from '../../../pages/bookings/index';
import { BookingType } from '../Booking';
import { Stepper } from './Stepper';
import styles from './TypeOfVisitStep.module.scss';

type TypeOfVisitStepProps = {
  bookingType: BookingType;
  handleNext: (data: BookingType) => void;
  maintenanceDisabled: boolean;
  letWithHavenDisabled: boolean;
  letWithHavenHidden: boolean;
  promoteLettingSignUp: boolean;
  letWithHavenEnabled: boolean;
  nextIsDisabled: boolean;
  selectedDates: EventDateSelection;
};

const RadioIcon: FunctionComponent<{
  selected: boolean;
  disabled?: boolean;
}> = ({ selected, disabled = false }) => {
  return (
    <span
      className={clsx(
        styles.icon,
        selected && styles.selected,
        disabled && styles.disabled
      )}
    />
  );
};

export const TypeOfVisitStep: FunctionComponent<TypeOfVisitStepProps> = ({
  bookingType,
  handleNext,
  letWithHavenDisabled,
  letWithHavenHidden,
  promoteLettingSignUp,
  letWithHavenEnabled,
  nextIsDisabled,
  selectedDates,
}) => {
  const { activeAccount } = useAuthContext();

  const [value, setValue] = useState<BookingType>(bookingType);

  const { lettingSummary } = useContext(LettingsContext);

  const handleNextClick = () => {
    handleNext(value);
  };

  const showGuestRegistration = useMemo(() => {
    if (!selectedDates?.startDate || activeAccount?.parkCode !== 'SA') {
      return true;
    }

    const hiddenRestrictionStartDate = DateTime.now()
      .set({ day: 1, month: 12, year: 2023 })
      .startOf('day');

    const hiddenRestrictionEndDate = DateTime.now()
      .set({ day: 2, month: 1, year: 2024 })
      .startOf('day');

    const startDate = selectedDates.startDate.startOf('day');

    const endDate = selectedDates.endDate
      ? selectedDates.endDate.startOf('day')
      : null;

    let shouldShowGuestRegistration = true;

    const overlapsRestrictedPeriod =
      startDate.toMillis() < hiddenRestrictionStartDate.toMillis() &&
      endDate &&
      endDate.toMillis() > hiddenRestrictionEndDate?.toMillis();

    if (
      isWithinInterval(
        hiddenRestrictionStartDate,
        hiddenRestrictionEndDate,
        startDate
      ) ||
      (endDate &&
        isWithinInterval(
          hiddenRestrictionStartDate,
          hiddenRestrictionEndDate,
          endDate
        )) ||
      overlapsRestrictedPeriod
    ) {
      shouldShowGuestRegistration = false;
    }

    return shouldShowGuestRegistration;
  }, [activeAccount?.parkCode, selectedDates.startDate, selectedDates.endDate]);

  return (
    <div className={styles.mainContainer}>
      <div>
        <div className={styles.formLabel}>
          <label>Purpose/Type of visit</label>
        </div>
        {!letWithHavenHidden && (
          <>
            {promoteLettingSignUp ? (
              <div
                className={styles.formControlWrapper}
                onClick={() =>
                  letWithHavenDisabled
                    ? {}
                    : setValue(BookingType.PROMOTE_LETTING)
                }
              >
                <RadioIcon
                  selected={value === BookingType.PROMOTE_LETTING}
                  disabled={letWithHavenDisabled}
                />
                <div className={clsx(styles.formText, styles.formBorder)}>
                  <p>Sign up and Earn</p>
                  <p>
                    Our letting service, Simply Letting, can help you offset
                    your holiday home&apos;s running costs. Completely
                    hassle-free, as the Haven team do all the legwork so I
                    don&apos;t have to!
                  </p>
                  <img src="/assets/sea.svg" />
                </div>
              </div>
            ) : (
              <>
                {letWithHavenEnabled ? (
                  <div
                    className={styles.formControlWrapper}
                    onClick={() =>
                      letWithHavenDisabled
                        ? {}
                        : setValue(BookingType.LET_WITH_HAVEN)
                    }
                  >
                    <RadioIcon
                      selected={value === BookingType.LET_WITH_HAVEN}
                      disabled={letWithHavenDisabled}
                    />
                    <div className={clsx(styles.formText, styles.formBorder)}>
                      <p>Let with Haven</p>
                      <p>
                        I’m registering a date to let with Haven, to earn some
                        extra income. Completely hassle-free, as the Haven team
                        will do the legwork so I don’t have to!
                      </p>
                      <img src="/assets/sea.svg" />
                    </div>
                  </div>
                ) : (
                  <div className={styles.comingSoonContainer}>
                    <div className={styles.comingSoon}>
                      COMING SOON
                      <img src="/assets/time.svg" alt="" />
                    </div>
                    <h2 className={styles.label}>Let with Haven</h2>
                    <p>
                      {lettingSummary?.let2Own
                        ? 'You’ll be able to register and manage your let with Haven breaks soon.'
                        : `I’m registering a date to let with Haven, to earn some
                      extra income. Completely hassle-free, as the Haven team do
                      all the legwork so I don’t have to!`}
                    </p>
                    <img src="/assets/sea.svg" alt="" />
                  </div>
                )}
              </>
            )}
          </>
        )}
        <div
          className={styles.formControlWrapper}
          onClick={() => setValue(BookingType.OWNER)}
        >
          <RadioIcon selected={value === BookingType.OWNER} />
          <div className={styles.formText}>
            <p>Owner visit</p>
            <p>I’m registering myself and my guests joining me.</p>
          </div>
        </div>
        {showGuestRegistration && (
          <div
            className={styles.formControlWrapper}
            onClick={() => setValue(BookingType.PRIVATE_LET)}
          >
            <RadioIcon selected={value === BookingType.PRIVATE_LET} />
            <div className={styles.formText}>
              <p>Guest Registration</p>
              <p>
                {activeAccount?.isCharity
                  ? 'Your Guests will receive a unique code to use for each stay in which to pre-book activities.'
                  : 'I’m registering guests staying in my holiday home.'}
              </p>
            </div>
          </div>
        )}

        <div
          className={styles.formControlWrapper}
          onClick={() => setValue(BookingType.MAINTENANCE)}
        >
          <RadioIcon selected={value === BookingType.MAINTENANCE} />
          <div className={styles.formText}>
            <p>Maintenance visit</p>
            <p>
              I’m registering a self-organised maintenance visit for my holiday
              home.
            </p>
          </div>
        </div>
      </div>
      <Stepper
        handleNext={() => handleNextClick()}
        handlePrev={() => {}}
        nextIsDisabled={nextIsDisabled}
        prevIsDisabled={true}
        dotsCount={0}
        activeDot={0}
      />
    </div>
  );
};
