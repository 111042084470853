import { isSameDate } from '@havenengineering/module-shared-owners-ui/dist/components/EventCalendar/utils';

import { InfoSelection } from '../components/EventCalendarApendix/InfoPanel';

interface ErrorMap {
  [key: number]: string;
}

const transportTypes = ['Car', 'Taxi', 'Bus', 'Train', 'Walking'];
export const modeOfTransportOptions = transportTypes.map((type) => ({
  value: type,
  label: type,
}));
export const timeSlotsOptions = [
  { value: '8.00AM - 9.00AM', label: '8.00am - 9.00am' },
  { value: '9.00AM - 10.00AM', label: '9.00am - 10.00am' },
  { value: '10.00AM - 11.00AM', label: '10.00am - 11.00am' },
  { value: '11.00AM - 12.00PM', label: '11.00am - 12.00pm' },
  { value: '12.00PM - 1.00PM', label: '12.00pm - 1.00pm' },
  { value: '1.00PM - 2.00PM', label: '1.00pm - 2.00pm' },
  { value: '2.00PM - 3.00PM', label: '2.00pm - 3.00pm' },
  { value: '3.00PM - 4.00PM', label: '3.00pm - 4.00pm' },
  { value: '4.00PM - 5.00PM', label: '4.00pm - 5.00pm' },
  { value: '5.00PM - 6.00PM', label: '5.00pm - 6.00pm' },
  { value: '6.00PM - 7.00PM', label: '6.00pm - 7.00pm' },
  { value: '7.00PM - 8.00PM', label: '7.00pm - 8.00pm' },
  { value: '8.00PM - 9.00PM', label: '8.00pm - 9.00pm' },
  { value: '9.00PM - 10.00PM', label: '9.00pm - 10.00pm' },
  { value: '10.00PM - 11.00PM', label: '10.00pm - 11.00pm' },
];

export const getDateArrivalFromSelection = (selection: InfoSelection) => {
  // Arrivals API accept ISO date string like "2023-01-01"
  if (isSameDate(selection.dates.startDate, selection.dates.endDate)) {
    const date = selection.dates.startDate.toISODate();
    return [date, date];
  }
  const startDate = selection.dates.startDate.toISODate();
  const endDate = selection.dates.endDate.toISODate();
  return [startDate, endDate];
};

const arrivalBookingErrorMap: ErrorMap = {
  500: 'Opps, something went wrong there. Please click on the submit button again in a few moments or try re-registering',
  400: 'Opps, something went wrong there. Please click on the submit button again in a few moments or try re-registering',
  502: "Our application isn't available right now. Please give it a moment and and try registering again.",
  503: "Our application isn't available right now. Please give it a moment and and try registering again.",
  504: "It's super busy right now, please try again later.",
};

export const getArrivalBookingErrorByStatus = (status: number) => {
  return status && arrivalBookingErrorMap[status]
    ? arrivalBookingErrorMap[status]
    : arrivalBookingErrorMap[500];
};

export const getFullAreaCode = (activeAccount: UserAccount | null) => {
  if (!activeAccount) return '';
  // we need the full areaCode like, areaCode = HT & zoneCode = 0 --> HT0
  if (activeAccount.areaCode && activeAccount.zoneCode) {
    return `${activeAccount.areaCode}${activeAccount.zoneCode}`;
  }
  return '';
};
