export interface DirectDebitBankDetails {
  accountName: string;
  accountNumber: string;
  sortCode: string;
  isUKAccountAndAccountHolder: string;
  canAuthoriseDD: string;
}

export interface DirectDebitPaymentSchedule {
  ownerAccountNumber: string;
  startDate: string;
  monthlyAmount: number;
  paymentDay: string;
  monthlyFrequency: number;
  paymentsRemaining: number;
  balanceType: DirectDebitBalanceType;
  yearlyTotal?: string;
}

export interface DirectDebit {
  directDebitId: number;
  accountId: number;
  year: number;
  amount: number;
  paymentDay: number;
  monthlyFrequency: number;
  startMonth: number;
  startYear: number;
  maxPayments: number;
  balanceType: DirectDebitBalanceType;
}

export enum DirectDebitBalanceType {
  Let2Own = 'Let2Own',
  Balance = 'Balance',
}

export enum DirectDebitAccountType {
  Let2Own = 'Let2Own',
  Standard = 'Standard',
}

export interface DirectDebitWebConfig {
  ddWebConfigurationId: number;
  effectiveFromDt: string;
  effectiveToDt: string;
  numberOfOccurrences: number;
  paymentDay: number;
  startMonth: number;
  startYear: number;
  accountType: DirectDebitAccountType;
  balanceType: DirectDebitBalanceType;
}

export interface DirectDebitConfigResponse {
  currentConfig: DirectDebitWebConfig;
}

export interface DirectDebitCreationDetails {
  balanceType: DirectDebitBalanceType;
  bankAccountName: string;
  bankSortCode: string;
  bankAccountNumber: string;
  amount: number;
}

interface DirectDebitSubSummary {
  canSetUpDD: boolean;
  schedule: DirectDebitWebConfig | null;
  directDebit: DirectDebit | null;
}

export interface DirectDebitSummary {
  standardDirectDebit: DirectDebitSubSummary;
  let2OwnDirectDebit: DirectDebitSubSummary;
  balance: {
    standard: number | null;
    let2Own: number | null;
  };
}
