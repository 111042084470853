import React, { FunctionComponent, useState } from 'react';
import clsx from 'clsx';

import styles from './BookingEditor.module.scss';
import DateEditor from './DateEditor';
import LettingEmailEditor from './LettingEmailEditor';
import ParticipantEditor from './ParticipantEditor';
import TransportEditor from './TransportEditor';

interface BookingEditorProps {
  isLetting: boolean;
}

const BookingEditor: FunctionComponent<BookingEditorProps> = ({
  isLetting,
}) => {
  const [activeTab, setActiveTab] = useState<RegistrationTab>('date');

  return (
    <div className={styles.editorWrapper} data-testid="booking-editor">
      <div className={styles.tabs}>
        <div
          data-testid="date-tab"
          className={clsx(styles.tab, activeTab === 'date' && styles.active)}
          onClick={() => setActiveTab('date')}
        >
          <img
            src="https://www.haven.com/assets/icons/calendar-blue.svg"
            alt="date"
          />
        </div>
        {isLetting && (
          <div
            data-testid="participants-tab"
            className={clsx(
              styles.tab,
              activeTab === 'participants' && styles.active
            )}
            onClick={() => setActiveTab('participants')}
          >
            <img
              src="https://www.haven.com/assets/icons/people-blue.svg"
              alt="participants"
            />
          </div>
        )}
        <div
          data-testid="transport-tab"
          className={clsx(
            styles.tab,
            activeTab === 'transport' && styles.active
          )}
          onClick={() => setActiveTab('transport')}
        >
          <img
            src="https://www.haven.com/assets/icons/car-blue.svg"
            alt="transport"
          />
        </div>
        {isLetting && (
          <div
            data-testid="letter-email-tab"
            className={clsx(
              styles.tab,
              activeTab === 'letterEmail' && styles.active
            )}
            onClick={() => setActiveTab('letterEmail')}
          >
            <img src="/assets/icon-mail.svg" alt="letterEmail" />
          </div>
        )}
      </div>
      {activeTab === 'date' && <DateEditor />}
      {activeTab === 'transport' && <TransportEditor />}
      {activeTab === 'participants' && <ParticipantEditor />}
      {activeTab === 'letterEmail' && <LettingEmailEditor />}
    </div>
  );
};

export default BookingEditor;
