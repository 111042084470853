import React from 'react';
import InlineMessaging, {
  InlineMessagingTheme,
} from '@havenengineering/module-shared-library/dist/components/InlineMessaging/InlineMessaging';
import { L2oSummary } from '@havenengineering/module-shared-owners-library/dist/interfaces/l2oSummary';
import { MODE } from '@havenengineering/module-shared-owners-ui/dist/components/Let2OwnBreakSummary';

import { Let2OwnBreaksSummaryWrapper } from '../Let2OwnBreakSummaryWrapper/Let2OwnBreakSummaryWrapper';
import styles from './Let2OwnRenewalCompleteBanner.module.scss';

interface Let2OwnRenewalCompletedBannerProps {
  let2OwnSummary: L2oSummary;
  mobileview: boolean;
  loading: boolean;
  currentDate: string;
}

export const Let2OwnRenewalCompletedBanner: React.FC<
  Let2OwnRenewalCompletedBannerProps
> = ({ let2OwnSummary, loading, currentDate }) => (
  <div className={styles.container}>
    <InlineMessaging
      type={InlineMessagingTheme.INFO_THEME}
      message="You won't be able to remove any breaks that would take you below your contracted minimums."
      className={styles.container}
      title="Removing breaks is limited"
      dismissible={false}
    />

    <Let2OwnBreaksSummaryWrapper
      isLoading={loading}
      let2OwnSummary={let2OwnSummary}
      currentDate={currentDate}
      mode={MODE.PRESENTATIONAL}
    />
  </div>
);
