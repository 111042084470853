import React, { FunctionComponent, useState } from 'react';
import InlineMessaging, {
  InlineMessagingTheme,
} from '@havenengineering/module-shared-library/dist/components/InlineMessaging/InlineMessaging';
import { SelectInputField } from '@havenengineering/module-shared-library/dist/form/components/SelectInput';
import { TextInputField } from '@havenengineering/module-shared-library/dist/form/components/TextInput';
import useForm from '@havenengineering/module-shared-library/dist/form/hooks/useForm';
import { Button } from '@havenengineering/module-shared-owners-ui/dist/components/Button';
import { DateTime } from 'luxon';

import { modeOfTransportOptions } from '../../helpers/arrivals';
import { updateBooking } from '../helpers/bookings';
import { useBooking } from './bookingContext';
import styles from './TransportEditor.module.scss';

type TransportEditorForm = {
  modeOfTransport: string | null;
  licencePlate1: string;
  licencePlate2: string;
};

const TransportEditor: FunctionComponent = () => {
  const { booking, handleTransportUpdate } = useBooking();

  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const licencePlateNumber = booking.licencePlateNumber.split(',');

  const { fields, submit } = useForm<TransportEditorForm>({
    initialState: {
      modeOfTransport: booking.modeOfTransport,
      licencePlate1: licencePlateNumber[0] || '',
      licencePlate2: licencePlateNumber[1] || '',
    },
    schema: {
      modeOfTransport: {
        required: true,
      },
    },
    submitCallback: async (state) => {
      handleSubmit(state);
    },
  });

  const handleSubmit = async (state: any) => {
    setError('');
    setSuccess(false);
    const formattedPlates = [state.licencePlate1, state.licencePlate2]
      .filter(Boolean)
      .join(',');

    if (state.modeOfTransport === 'Car' && formattedPlates.length === 0)
      return setError('Licence Plate is required');

    setLoading(true);
    try {
      const dueToArriveDate = DateTime.fromISO(booking.dateArrival).toISODate();
      const dueToLeaveDate = DateTime.fromISO(
        booking.dueToLeaveDate
      ).toISODate();
      const response = await updateBooking(
        {
          modeOfTransport: state.modeOfTransport,
          licencePlateNumber:
            state.modeOfTransport === 'Car' ? formattedPlates : '',
          timeArrival: booking.timeArrival,
          dueToArriveDate,
          dueToLeaveDate,
        },
        booking
      );
      handleTransportUpdate({
        modeOfTransport: response.modeOfTransport,
        licencePlateNumber: response.licencePlateNumber,
      });
      setSuccess(true);
    } catch (error) {
      setError('Something went wrong, please try again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className={styles.editorContainer}
      onSubmit={(event) => event.preventDefault()}
      data-testid="transport-editor"
    >
      <SelectInputField
        id="modeOfTransport"
        label="Mode of Transport"
        field={fields.modeOfTransport}
        options={modeOfTransportOptions}
        data-testid="mode-of-transport-field"
      />
      {fields.modeOfTransport.value === 'Car' && (
        <>
          <TextInputField
            className="fs-mask"
            id="licencePlate1"
            label="Licence Plate 1"
            field={fields.licencePlate1}
            type="text"
          />
          <TextInputField
            className="fs-mask"
            id="licencePlate2"
            label="Licence Plate 2"
            field={fields.licencePlate2}
            type="text"
          />
        </>
      )}
      {error && (
        <InlineMessaging
          type={InlineMessagingTheme.ERROR_THEME}
          title="Error"
          message={error}
          dismissible={false}
        />
      )}
      <Button onClick={() => submit()} isLoading={loading}>
        Save Transport
      </Button>
      {success && (
        <InlineMessaging
          type={InlineMessagingTheme.SUCCESS_THEME}
          title="Success"
          message="Update was successful"
          dismissible={false}
        />
      )}
    </form>
  );
};

export default TransportEditor;
